export const HTTP_STATUS = Object.freeze({ UNAUTHORIZED: 401 });
export const STORAGEKEY = Object.freeze({
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  ACCESS_TIME: 'access_time',
});
export const API_PATH = Object.freeze({
  LOGIN: 'v0.2/merchant/webLogin',
  LOGOUT: 'v0.2/merchant/logout',
  FORGOT_EMAIL: 'v0.2/merchant/forgotEmail',
  FORGOT_PASSWORD: 'v0.2/merchant/forgotPassword',
  RESET_PASSWORD: 'v0.2/merchant/changePassword',
  DASHBOARD: 'v0.2/merchant/dashboard',
  PAYMENT_HISTORY: 'v0.2/merchant/payHistory',
  PAYMENT_HISTORY_EXPORT_CSV: 'v0.2/merchant/exportPayHistory',
  REQUESTING_REFUND: 'v0.2/merchant/pendingTransaction',
  REQUESTING_REFUND_EXPORT_CSV: 'v0.2/merchant/exportPendingTransaction',
  BILL_HISTORY: 'v0.2/merchant/refundedHistory',
  BILL_HISTORY_EXPORT_CSV: 'v0.2/merchant/exportRefundedHistory',
  SHOP_INFO: 'v0.2/merchant/infor',
  HEARD_INFO: 'v0.2/merchant/headerProfile',
  SETTING: 'v0.2/merchant/setting',
  REFUND_AMOUNT_HISTORY_LIST: 'v0.2/merchant/refundAmountHistory',
  SUM_REFUND_HISTORY_LIST: 'v0.2/merchant/sumRefundHistory',
  SUM_REFUND_HISTORY_EXPORT_CSV: 'v0.2/merchant/exportSumRefundedHistory'
});
