import { StyleSheet } from 'react-native';
import { TEXT_COLOR_RED, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  wrapperHeader: {
    marginVertical: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 20,
  },
  flexBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapperContent: {
    maxWidth: 900,
    marginTop: 40,
  },
  wrapperFrom: {
    marginBottom: 15,
  },

  flexAlignCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconDelete: {
    width: 24,
    height: 24,
    marginRight: 10,
    marginBottom: 5,
  },
  iconUser: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  iconBuilding: {
    width: 27,
    height: 33,
    marginRight: 10,
  },
  iconBank: { width: 30, height: 20, marginHorizontal: 20 },
  iconLeft: { width: 12, height: 22, marginHorizontal: 20 },
  colorRed: {
    color: TEXT_COLOR_RED,
  },
  pl20: {
    paddingHorizontal: 40,
  },
  textHeader: {
    fontSize: 18,
  },
  textHeaderId: {
    fontSize: 18,

    textAlign: 'end',
    flex: 1,
    marginBottom: 50,
  },
  dFlex: { flexDirection: 'row', alignItems: 'center' },
  textBack: { color: TEXT_COLOR_GREEN, fontSize: 18 },
});

export default styles;
