import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';

import AccountInput from '@components/AccountComponents/AccountInput';
import InputImage from '@components/AccountComponents/InputImage';
import FullName from './partials/FullName';
import { JText as Text } from '@components/common/JText';
import JInput from '@components/common/JInput';
import LocationForm from './partials/LocationForm';
import ContactForm from './partials/ContactForm';
import { formIndividualData } from './partials/formData';
import TransactionPersonForm from './partials/TransactionPersonForm';
import TransactionContactForm from './partials/TransactionContactForm';
import styles from './styles';

const FromIndividual = (props) => {
  const { control, formValue } = props;
  const renderForm = (item, control, setValue) => {
    switch (item.type) {
      case 'DEFAULT':
        return (
          <AccountInput
            disable={false}
            key={item.name}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subLabelText={item.subLabelText}
            control={control}
            subfix={item.subfix}
            name={item.name}
            startIcon={item.startIcon}
            endIcon={item.endIcon}
            height={item.height}
            multiline={item.multiline}
          />
        );
      case 'IMAGE_PICKER':
        return (
          <InputImage
            formValue={formValue}
            accountId={formValue?.id}
            disable={false}
            setValue={setValue}
            key={item.name}
            control={control}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subfix={item.subfix}
            name={item.name}
          />
        );
      default:
        break;
    }
  };
  const copyClipBoard = () => {
    try {
      navigator.clipboard.writeText(formValue?.leviasChainAddress);
    } catch (error) {
      console.error('copy clipboard only work with https');
    }
  };
  return (
    <View style={{ paddingBottom: 40 }}>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <View style={{ flexDirection: 'row' }}>
            <Text>LEVICA ID</Text>
            <Text style={{ color: 'red', paddingLeft: 5 }}>✴︎</Text>
          </View>
        </Col>
        <Col size={8}>
          {formValue?.id ? (
            <Text style={{ color: '#a5a5a5', paddingLeft: 20 }}>
              S{parseInt(formValue?.id) < 9999 ? `0000${formValue?.id}`.slice(-4) : formValue?.id}
            </Text>
          ) : (
            <Text style={{ color: '#a5a5a5a8' }}> ※LEVICA IDは自動で割り当てられます</Text>
          )}
        </Col>
      </Grid>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text>ウォレットアドレス</Text>
            <Text style={{ color: 'red', paddingLeft: 5 }}>✴︎</Text>
          </View>
        </Col>
        <Col size={6}>
          {formValue?.leviasChainAddress ? (
            <TouchableOpacity activeOpacity={0.7} onPress={() => copyClipBoard()}>
              <View style={{ marginRight: 150, minWidth: 350 }}>
                <JInput
                  placeholder="ウォレットアドレスがありません"
                  subfix="saveClipboard.svg"
                  widthSubfix={18}
                  heightSubfix={18}
                  control={control}
                  disable={false}
                  name="leviasChainAddress"
                />
              </View>
            </TouchableOpacity>
          ) : (
            <Text style={{ color: '#a5a5a5a8' }}> ※ウォレットアドレスは自動で割り当てられます</Text>
          )}
        </Col>
        <Col size={2}></Col>
      </Grid>
      {/* form LEVICA ID to 従業員数 */}
      {formIndividualData.map((i) => renderForm(i))}
      {/* from 所在地  to 階数・号室  */}
      <LocationForm control={control} />
      {/* from 連絡先  to  */}
      <ContactForm control={control} />
      {/* from 代表者  to  */}
      <FullName control={control} />
      {/* from 取引担当者  to  */}
      <TransactionPersonForm control={control} />
      {/* from 取引担当者住所  to  */}
      <TransactionContactForm control={control} />
    </View>
  );
};

export default FromIndividual;
