import React from 'react';
import { View, ImageBackground } from 'react-native';
import { Col } from 'react-native-easy-grid';

import { JText as Text } from '@components/common/JText';
import formatNumber from '@helper/formatNumber';
import styles from '../styles';

const Card = ({ data }) => {
  return (
    <>
      <Col style={{ minHeight: 180 }}>
        <ImageBackground
          style={styles.CardImgBackground}
          resizeMode="cover"
          imageStyle={{ minHeight: 180 }}
          source={require(`@assets/img/bg-green.jpg`)}
        >
          <View style={styles.CardContent}>
            <Text adjustsFontSizeToFit={true} style={styles.CardContentNumber}>
              {data?.totalPendingRefundPoint >= 0 ? `${formatNumber(data?.totalPendingRefundPoint)} Point` : ` `}
            </Text>
            <Text adjustsFontSizeToFit={true} style={styles.CardContentText}>
              未請求売上
            </Text>
          </View>
        </ImageBackground>
      </Col>
      <Col style={{ minHeight: 180 }}>
        <ImageBackground
          style={styles.CardImgBackground}
          resizeMode="cover"
          imageStyle={{ minHeight: 180 }}
          source={require(`@assets/img/bg-blue.jpg`)}
        >
          <View style={styles.CardContent}>
            <Text adjustsFontSizeToFit={true} style={styles.CardContentNumber}>
              {data?.totalRefundedPoint >= 0 ? `¥ ${formatNumber(data?.totalRefundedPoint)}` : ` `}
            </Text>
            <Text adjustsFontSizeToFit={true} style={styles.CardContentText}>
              全請求履歴一覧
            </Text>
          </View>
        </ImageBackground>
      </Col>
      <Col style={{ minHeight: 180 }}>
        <ImageBackground
          style={styles.CardImgBackground}
          resizeMode="cover"
          imageStyle={{ minHeight: 180 }}
          source={require(`@assets/img/bg-violet.jpg`)}
        >
          <View style={styles.CardContent}>
            <Text adjustsFontSizeToFit={true} style={styles.CardContentNumber}>
              {data?.totalRequestRefundPoint >= 0 ? `¥ ${formatNumber(data?.totalRequestRefundPoint)}` : ` `}
            </Text>
            <Text adjustsFontSizeToFit={true} style={styles.CardContentText}>
              払戻請求中一覧
            </Text>
          </View>
        </ImageBackground>
      </Col>
    </>
  );
};

export default Card;
