import { StyleSheet } from 'react-native';
import {
  BG_ACTIVE_SIDEBAR,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_HEADER,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_HEADER_LOGIN,
} from '@assets/style/styleDefault';

const btnAction = {
  fontWeight: 400,
  lineHeight: 1.5,
  cursor: 'pointer',
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'initial',
  height: '55px',
  width: '16vw',
  minWidth: 240,
  borderRadius: 4,
};

const styles = StyleSheet.create({
  backgroundColor: {
    backgroundColor: BG_ACTIVE_SIDEBAR,
  },
  backgroundVideo: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    height: '100%',
    backgroundSize: 'cover',
  },
  backgroundImg: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  backGroup: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  form: {
    backgroundColor: 'transparent',
    display: 'flex',
    position: 'relative',
    paddingRight: '48px',
    paddingLeft: '48px',
    WebkitBoxFlex: '0',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    width: '100%',
    maxWidth: '100%',
    minWidth: '250px',
    marginTop: '10%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    paddingBottom: 50,
  },
  textHeader: {
    fontFamily: FONT_FAMILY_HEADER,
    margin: '0',
    display: 'block',
    marginInlineStart: 0,
    marginInlineEnd: 0,
  },
  colorS: {
    paddingLeft: 16,
    color: COLOR_TEXT_DEFAULT,
    fontSize: '25px',
    fontWeight: 500,
    fontFamily: FONT_FAMILY_HEADER,
    minWidth: 120,
  },
  formBody: {
    paddingRight: '50px',
    paddingLeft: '30px',
    paddingTop: '20px',
    paddingBottom: '30px',
    display: 'flex',
    alignItems: 'center',
  },

  formAction: {
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  buttonSubmit: {
    ...btnAction,
  },
  textBtnSubmit: {
    color: COLOR_TEXT_DEFAULT,
  },
  text: {
    padding: 40,
    minWidth: 120,
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
  },
  textTitle: {
    minWidth: 120,
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER_LOGIN,
    paddingTop: 10,
    fontWeight: 500,
    minWidth: 120,
  },
  textClose: {
    paddingLeft: 10,
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
  },
  btnClose: {
    flexDirection: 'row',
    zIndex: 9,
    paddingLeft: 20,
    paddingTop: 20,
  },
  flexDirection: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconClose: { width: 32, height: 24 },
});

export default styles;
