import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, Image, TextInput, View } from 'react-native';

import { COLOR_TEXT_DEFAULT, INPUT_BG, TEXT_COLOR_HEADER_TABLE } from '@assets/style/styleDefault';
import { JText as Text } from './JText';
import JButton from './JButton';
import { Col, Grid } from 'react-native-easy-grid';

const JNumber = ({
    colorTextInput = COLOR_TEXT_DEFAULT,
    placeholder,
    width = '100%',
    subfix,
    widthSubfix,
    heightSubfix,
    rightSubfix = 20,
    prefix,
    widthPrefix,
    heightPrefix,
    topPrefix = 10,
    control,
    name,
    backgroundColor = INPUT_BG,
    multiline,
    numberOfMulti,
    heightMulti,
    borderError = '#FF0000',
    paddingLeft,
    paddingRight,
    border = '1px solid transparent',
    marginBottomErr,
    disable = true,
    borderRadius = 5,
    secureTextEntry = false,
    heightInput = 42,
    textAlignInput,
    cursor,
    onPressEnter = null,
}) => {
    const [isFocus, setFocus] = useState(false);
    return (
        <>
            <View style={[styles.relative, styles.wrapperInput, { width: width }]}>
                <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error, ...rest } }) => (
                        <>
                            <JButton
                                style={[styles.plusBtn]}
                                text="+"
                                textColor="#fff"
                                heightIcon={21}
                                widthIcon={21}
                                disabled={false}
                                onPressAction={()=>{}}
                            />
                            <TextInput
                                keyboardType='numeric'
                                placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                                secureTextEntry={secureTextEntry}
                                style={[
                                    styles.input,
                                    {
                                        backgroundColor: backgroundColor,
                                        color: !disable ? '#A5A5A5' : colorTextInput,
                                        height: heightInput,
                                        outline: isFocus && !error && disable ? '2px solid #00BE85' : 'none',
                                    },
                                    multiline && { height: heightMulti, paddingTop: '10px' },
                                    error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                                    { paddingLeft: 20 },
                                    marginBottomErr && {
                                        marginBottom: marginBottomErr,
                                    },
                                    { borderRadius: borderRadius },
                                    { textAlign: textAlignInput },
                                    paddingRight && { paddingRight: paddingRight },
                                    { cursor: cursor },
                                ]}
                                onChangeText={(text) => {
                                    let numericInput = text.replace(/[^0-9]/g, '');
                                    onChange(numericInput);
                                }}
                                value={value ?? ''}
                                placeholder={placeholder}
                                multiline={multiline}
                                editable={disable}
                                onFocus={() => setFocus(true)}
                                onBlur={() => setFocus(false)}
                            />
                            <Text style={styles.error}>{error?.message}</Text>
                        </>
                    )}
                    name={name}
                />
        </View >
        </>
    );
};

const styles = StyleSheet.create({
    relative: {
        position: 'relative',
    },
    input: {
        fontSize: 14,
        fontFamily: 'NotoSansJP',
        borderRadius: 4,
        paddingLeft: 50,
        paddingRight: '1rem',
        outlineStyle: 'none',
        marginHorizontal: 2,
    },
    input2: {
        fontSize: 14,
        fontFamily: 'NotoSansJP',
        borderRadius: 4,
        paddingLeft: 50,
        paddingRight: '1rem',
        outlineStyle: 'none',
        marginHorizontal: 2,
    },
    inputMulti: {
        fontSize: 14,
        fontFamily: 'NotoSansJP',
        paddingLeft: 50,
        borderRadius: 4,
        paddingRight: '1rem',
        outlineStyle: 'none',
        marginHorizontal: 2,
    },
    error: {
        color: 'red',
        position: 'absolute',
        bottom: -25,
        left: 0,
    },
    verticleLine: {
        position: 'absolute',
        top: 16,
        right: 52,
        height: 20,
        width: 1,
        backgroundColor: '#909090',
    },
    plusBtn : {
        position: 'absolute',
        left: 0,
        width: 52,
        height:52
    }
});

export default JNumber;
