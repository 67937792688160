import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinearGradient } from 'expo-linear-gradient';
import { Video } from 'expo-av';

import JInput from '@components/common/JInput';
import {
  TXT_CONFIRM_PASSWORD,
  TXT_INPUT_CONFIRM_PASSWORD,
  TXT_NEW_PASSWORD,
  TXT_MAX_32,
  TXT_VALIDATE_NEW_PASSWORD,
} from '@helper/stringHelper';
import { JText as Text } from '@components/common/JText';
import { post } from '@utils/RequestLevica';
import styles from './style';
import { API_PATH } from '@helper/constant';

const schema = Yup.object().shape({
  password: Yup.string()
    .min(8, TXT_VALIDATE_NEW_PASSWORD)
    .max(32, TXT_MAX_32)
    .matches(/^((?=.*\d)(?=.*[A-Z]).{8,32})$/, TXT_VALIDATE_NEW_PASSWORD)
    .required(TXT_NEW_PASSWORD),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], TXT_CONFIRM_PASSWORD)
    .required(TXT_INPUT_CONFIRM_PASSWORD),
});

const ResetPassword = () => {
  const history = useHistory();
  const [isErrorBackgroundVideo, setErrorBackgroundVideo] = useState(false);
  let { search } = useLocation();
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (password) => {
    try {
      await post(API_PATH.RESET_PASSWORD, {
        ...password,
        registrationToken: search?.slice(7),
      });
      history.push('/reset-password/completed');
    } catch (error) {
      setError('password', {
        type: 'server',
        message: '',
      });
      setError('confirmPassword', {
        type: 'server',
        message: 'トークンの有効期限が切れました。',
      });
    }
  };

  return (
    <>
      <View style={styles.backgroundColor}>
        {isErrorBackgroundVideo ? (
          <Image style={styles.backgroundImg} source={require('@assets/img/login.jpg')} alt="background login" />
        ) : (
          <Video
            source={require('@assets/videos/login-background.mp4')}
            style={styles.backgroundVideo}
            posterStyle={styles.backgroundImg}
            rate={1}
            shouldPlay={true}
            isLooping={true}
            isMuted={true}
            posterSource={require('@assets/img/login.jpg')}
            usePoster={true}
            onError={() => setErrorBackgroundVideo(true)}
            VideoNaturalSize={{ width: 1200 }}
            resizeMode="cover"
          />
        )}
        <View style={styles.backGroup}>
          <NavLink to={'/'} style={{ textDecorationLine: 'none' }}>
            <View style={styles.btnClose}>
              <Image source={require('@assets/icons/closeGreen.svg')} style={styles.iconClose} />
              <View>
                <Text style={styles.textClose}>キャンセル</Text>
              </View>
            </View>
          </NavLink>
          <View style={styles.form}>
            <View style={styles.formHeader}>
              <View style={styles.textHeader}>
                <Text style={styles.colorS}>パスワードの再設定</Text>
              </View>
            </View>
            <View style={styles.formBody}>
              <View style={styles.padding10}>
                <JInput
                  placeholder="Please enter your new password."
                  width="100%"
                  prefix="lock.svg"
                  widthPrefix={19}
                  heightPrefix={23}
                  topPrefix={11}
                  control={control}
                  secureTextEntry={true}
                  name="password"
                  borderError={errors.password ? '#FF0000' : '#5C5C5C'}
                  heightInput={52}
                />
              </View>
              <View style={styles.padding10}>
                <JInput
                  onPressEnter={handleSubmit(onSubmit)}
                  placeholder="Please enter your confirm new password."
                  width="100%"
                  prefix="lock.svg"
                  widthPrefix={19}
                  heightPrefix={23}
                  topPrefix={11}
                  control={control}
                  secureTextEntry={true}
                  name="confirmPassword"
                  borderError={errors.confirmPassword ? '#FF0000' : '#5C5C5C'}
                  heightInput={52}
                />
              </View>
              <View>
                <Text style={styles.text}>
                  パスワードは、8文字以上の英数字で英文字の大文字と小文字 を含めてください。
                </Text>
              </View>
              <View style={styles.formAction}>
                {isDirty && isValid ? (
                  <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
                    <TouchableOpacity
                      onPress={() => {
                        handleSubmit(onSubmit)();
                      }}
                    >
                      <View style={[styles.buttonSubmit]}>
                        <View style={styles.buttonSubmit}>
                          <Text style={styles.textBtnSubmit}>パスワードの再設定</Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  </LinearGradient>
                ) : (
                  <View style={styles.buttonAction}>
                    <Text style={styles.textBtnAction}>パスワードの再設定</Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default ResetPassword;
