import React from 'react';
import { StyleSheet, TouchableWithoutFeedback, Image, View } from 'react-native';
import { BTN_COLOR_GREY, FONT_SIZE_DEFAULT, INPUT_BG, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { JText as Text } from './JText';

const JButton = ({
  text,
  width = '100%',
  textColor,
  textColorDisabled = TEXT_COLOR_GREY,
  btnColor,
  linkIcon,
  heightIcon,
  widthIcon,
  marginBottomIcon = 0,
  iconSubfix,
  heightSubfix,
  widthSubfix,
  onPressAction,
  disabled,
  btnDisabledColor = INPUT_BG,
  linkIconDisabled,
  linkSubfixDisabled,
  flexStart,
  justifyContent = 'flex-start',
  style,
  borderError,
  borderBtn = '1px solid transparent',
  fontSize = 17
}) => {
  return (
    <TouchableWithoutFeedback disabled={disabled} onPress={onPressAction ? () => onPressAction() : () => {}}>
      <View
        style={[
          styles.button,
          { width: width },
          borderError ? { border: '1px solid red' } : { border: borderBtn },
          flexStart && { justifyContent: justifyContent, paddingLeft: 4 },
          disabled ? { backgroundColor: btnDisabledColor } : { backgroundColor: `${btnColor}` },
          style,
        ]}
      >
        {linkIcon && (
          <Image
            style={[styles.icon, { width: widthIcon, height: heightIcon, marginBottom: marginBottomIcon }]}
            source={
              disabled ? require(`../../assets/icons/${linkIconDisabled}`) : require(`../../assets/icons/${linkIcon}`)
            }
          />
        )}
        <Text style={[styles.text, disabled ? { color: `${textColorDisabled}` } : { color: `${textColor}` }, {fontSize: fontSize} ]}>{text}</Text>
        {iconSubfix && (
          <Image
            style={[styles.icon, { width: widthSubfix, height: heightSubfix }]}
            source={
              disabled
                ? require(`../../assets/icons/${linkSubfixDisabled}`)
                : require(`../../assets/icons/${iconSubfix}`)
            }
          />
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default JButton;

const styles = StyleSheet.create({
  button: {
    borderRadius: 4,
    height: 42,
    fontWeight: 400,
    lineHeight: 1.5,
    cursor: 'pointer',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  text: {
    color: TEXT_COLOR_GREY,
    marginHorizontal: 15,
  },
  icon: {
    resizeMode: 'contain',
    // marginRight: 20,
  },
});
