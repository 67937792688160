import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { TouchableOpacity } from 'react-native-web';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { JText as Text } from '../common/JText';

const HeaderAvatar = () => {
  const history = useHistory();
  const toggleModal = () => {
    history.push('/shop/details');
  };
  const userInfo = useSelector((state) => state.merchantUserInfo);

  return (
    <View>
      <TouchableOpacity onPress={() => toggleModal()}>
        <View style={styles.avatar}>
          <Text style={styles.textAvatar}>{userInfo?.name}店</Text>
          {userInfo?.icon ? (
            <Image style={styles.imgAvatar} source={{ uri: userInfo?.icon }} />
          ) : (
            <Image style={styles.imgAvatar} source={require(`@assets/img/userDefaultAvatar.png`)} />
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default HeaderAvatar;

const styles = StyleSheet.create({
  avatar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'initial',
  },
  textAvatar: {
    fontSize: 20,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 'bold',
  },
  imgAvatar: {
    height: 45,
    width: 45,
    borderRadius: 100,
  },
});
