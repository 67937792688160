import React, { memo } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';

import { INPUT_BG, TEXT_COLOR_RED } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import JInput from '../common/JInput';

const InputImage = (props) => {
  const { placeholder, labelText, isRequired, formValue, disable = true, control } = props;

  return (
    <Grid style={[styles.marginBottom20, { minHeight: 'unset' }]}>
      <Col size={4}>
        <View style={[styles.mt5, styles.flexRow]}>
          <Text style={styles.colorWhite}>{labelText}</Text>
          {isRequired && <Text style={styles.iconRequired}>✴︎</Text>}
        </View>
      </Col>
      <Col size={8}>
        <Grid style={{ gap: 15 }}>
          <Col size={3} style={styles.warperImage}>
            {formValue?.icon ? (
              <Image style={styles.image} source={{ uri: formValue?.icon }} />
            ) : (
              <Image style={styles.imageDefault} source={require('@assets/img/img-default.png')} />
            )}
          </Col>
          <Col size={9}>
            <JInput disable={disable} name="iconName" control={control} placeholder={placeholder} />
            <View></View>
          </Col>
        </Grid>
      </Col>
    </Grid>
  );
};

export default memo(InputImage);

const styles = StyleSheet.create({
  relative: {
    position: 'relative',
  },
  input: {
    border: `1px solid ${INPUT_BG}`,
    height: 42,
    borderRadius: 4,
    paddingLeft: 15,
    paddingRight: 40,
    outlineStyle: 'none',
    paddingRight: 30,
    backgroundColor: INPUT_BG,
    fontSize: 13,
  },
  inputSubfix: {
    height: 15,
    width: 15,
    position: 'absolute',
    right: 15,
    bottom: 13,
    flex: 1,
    resizeMode: 'contain',
  },
  textRed: {
    color: TEXT_COLOR_RED,
  },
  mt5: {
    marginTop: 5,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  marginBottom20: {
    marginBottom: 20,
  },
  warperImage: {
    minWidth: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
  },
  image: {
    width: 90,
    height: 90,
    borderRadius: 90,
  },
  imageDefault: {
    width: '8vw',
    height: '5vw',
    minHeight: 90,
    minWidth: 110,
    maxWidth: 150,
    maxHeight: 100,
    borderRadius: 0,
    marginLeft: 5,
  },
  imageContainer: {
    minHeight: 'unset',
  },
  hidden: {
    display: 'none',
  },
});
