export const formIndividualData = [
  {
    placeholder: 'ログイン用のメールアドレスを入力してください',
    isRequired: true,
    labelText: 'メールアドレス',
    name: 'merchantUserInformationEmail',
    type: 'DEFAULT',
  },
  {
    placeholder: 'ニックネームを入力してください',
    isRequired: true,
    labelText: 'ニックネーム',
    name: 'name',
    type: 'DEFAULT',
  },
  {
    placeholder: '画像 ファイルを選択してください',
    isRequired: false,
    labelText: 'ユーザーアイコン',
    name: 'icon',
    type: 'IMAGE_PICKER',
  },
  {
    placeholder: '商号を入力してください',
    isRequired: true,
    labelText: '商号',
    name: 'tradeName',
    type: 'DEFAULT',
  },

  {
    placeholder: '法人の種類を選択してください',
    isRequired: true,
    labelText: '法人の種類 ',
    name: 'corporationType',
    type: 'DEFAULT',
  },
  {
    placeholder: '上場している 場合は上場 市場名を入力してください',
    isRequired: true,
    labelText: '市場情報 ',
    name: 'marketInfor',
    type: 'DEFAULT',
  },
  {
    placeholder: '法人 番号を入力してください',
    isRequired: false,
    labelText: '法人番号',
    name: 'corporateNumber',
    type: 'DEFAULT',
  },
  //DATE PICKER
  {
    placeholder: '---- 年　---- 月　---- 日',
    isRequired: true,
    labelText: '設立',
    name: 'establishedAt',
    type: 'DEFAULT',
  },
  {
    placeholder: '金額を入力してください',
    isRequired: true,
    labelText: '資本金',
    name: 'capital',
    startIcon: '¥',
    type: 'DEFAULT',
  },
  {
    placeholder: '業種を選択してください',
    isRequired: true,
    labelText: '業種  ',
    name: 'industry',
    type: 'DEFAULT',
  },
  {
    placeholder: '事業 内容を●●字以内で入力してください',
    isRequired: true,
    multiline: true,
    labelText: '事業内容',
    subLabelText: '資本準備金含む',
    name: 'businessContent',
    height: 100,
    type: 'DEFAULT',
  },
  {
    placeholder: 'URLを入力してください',
    isRequired: false,
    labelText: 'ホームページ',
    name: 'homePageUrl',
    type: 'DEFAULT',
  },
  {
    placeholder: '従業員数を入力してください',
    isRequired: false,
    labelText: '従業員数',
    name: 'numberOfEmployees',
    type: 'DEFAULT',
    endIcon: '名',
  },
];
