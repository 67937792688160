import React from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import { saveAs } from 'file-saver';

import JButton from '../common/JButton';
import { JText as Text } from '../common/JText';
import { BG_COLOR, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import { LinearGradient } from 'expo-linear-gradient';
import ModalBackDrop from './ModalBackDrop';

const ModalImagePreview = (props) => {
  const { isModal, setIsModal, qrCode = false, cardUrl = false, name = 'QRcode' } = props;
  const qrCodeUrl = `data:image/png;base64,${qrCode}`;
  const downloadImage = () => {
    if (qrCode) {
      saveAs(qrCodeUrl, `${name}.jpg`);
    } else {
      saveAs(cardUrl, `${name}.jpg`);
    }
  };
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModal}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setIsModal(false)} />}
      >
        <View>
          <View style={styles.modalBack}>
            <View style={styles.headerModal}>
              <Text style={styles.textModal}>{name}</Text>
              <TouchableOpacity onPress={() => setIsModal(false)} style={styles.btnBack}>
                <Image style={styles.backIcon} source={require(`@assets/icons/closeGreen.svg`)} />
                <Text style={{ color: TEXT_COLOR_GREEN }}>閉じる</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.contentModal}>
              {qrCode || cardUrl ? (
                <>
                  {/* {card ? <Image source={require(`@assets/img/cardImage.png`)} style={styles.images} /> : null} */}
                  {cardUrl ? <Image source={{ uri: cardUrl }} style={styles.images} /> : null}

                  {qrCode ? <Image source={{ uri: qrCodeUrl }} style={styles.qrCode} /> : null}
                </>
              ) : (
                <Text style={styles.textNodata}>データがありません</Text>
              )}
            </View>
            {qrCode || cardUrl ? (
              <View style={styles.buttonModal}>
                <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
                  <JButton
                    text="保存"
                    textColor="#ffffff"
                    width={150}
                    hasIcon
                    linkIcon="save.svg"
                    widthIcon={20}
                    heightIcon={20}
                    onPressAction={() => {
                      downloadImage();
                      setIsModal(false);
                    }}
                  />
                </LinearGradient>
              </View>
            ) : null}
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  btnBack: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'absolute',
    right: 35,
  },
  images: {
    width: 550,
    height: 350,
  },
  qrCode: {
    width: 350,
    height: 350,
  },

  backIcon: {
    width: 18,
    height: 18,
    marginRight: 10,
  },

  modalClose: {
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: 10,
    marginLeft: 20,
  },
  wrapperContentText: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 30,
  },
  wrapperLable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 180,
    marginRight: 30,
  },
  wrapperNumber: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  numberText: {
    fontSize: 16,
  },
  iconClose: {
    height: 15,
    width: 15,
    marginRight: 10,
  },
  textClose: {
    fontSize: 14,
    color: '#f2f2f2',
  },
  modalBack: {
    backgroundColor: BG_COLOR,
    maxWidth: 100,
    minWidth: 700,
    borderRadius: 4,
    paddingBottom: 30,
  },
  headerModal: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  textModal: {
    color: 'white',
    fontSize: 40,
  },
  contentModal: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
  },
  iconModal: {
    height: 35,
    width: 42,
  },
  iconDanger: {
    height: 40,
    width: 49,
  },
  textContentModal: {
    marginTop: 30,
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonModal: {
    height: 90,
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'space-evenly',
  },
  iconList: {
    width: 20,
    height: 25,
    marginRight: 10,
  },
  textNodata: {
    paddingBottom: 50,
  },
});

export default ModalImagePreview;
