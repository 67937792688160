import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';

import { BORDER_ERROR, TEXT_COLOR_GREY, TEXT_COLOR_RED } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import DateTimePicker from '../common/DateTimePicker';
import JInput from '../common/JInput';

const AccountInput = ({
  placeholder,
  name,
  control,
  subfix,
  multiline = false,
  labelText,
  subLabelText,
  isRequired,
  startIcon,
  endIcon,
  ml,
  dateTimePicker = false,
  type,
  disable,
}) => {
  return (
    <Grid style={[styles.marginBottom20, { minHeight: 'unset' }]}>
      <Col size={4} style={styles.titleWithIcon}>
        <View>
          <View style={[styles.mt5, styles.flexRow]}>
            <Text style={[styles.colorWhite, { marginLeft: ml }]}>{labelText}</Text>
            {isRequired && <Text style={styles.iconRequired}>✴︎</Text>}
          </View>
          {subLabelText && <Text style={styles.textGrey}>{subLabelText}</Text>}
        </View>
        {startIcon && <Text style={{ marginRight: 10 }}>{startIcon}</Text>}
      </Col>
      {!dateTimePicker ? (
        <Col size={8} style={[styles.flexCenter]}>
          {!endIcon ? (
            <JInput
              disable={disable}
              name={name}
              control={control}
              placeholder={placeholder}
              subfix={subfix}
              widthSubfix={15}
              heightSubfix={15}
              rightSubfix={10}
              multiline={multiline}
              heightMulti={100}
              borderError={BORDER_ERROR}
              paddingLeft={10}
              paddingRight={31}
            />
          ) : (
            <Grid>
              <Col size={11}>
                <JInput
                  disable={disable}
                  name={name}
                  control={control}
                  placeholder={placeholder}
                  subfix={subfix}
                  widthSubfix={15}
                  heightSubfix={15}
                  rightSubfix={10}
                  multiline={multiline}
                  heightMulti={100}
                  borderError={BORDER_ERROR}
                  paddingLeft={10}
                  paddingRight={31}
                />
              </Col>
              <Col size={1} style={styles.flexCenter}>
                <Text style={styles.colorWhite}>{endIcon}</Text>
              </Col>
            </Grid>
          )}
        </Col>
      ) : (
        <Col size={8}>
          <DateTimePicker disable={disable} name={name} control={control} type={type} />
        </Col>
      )}
    </Grid>
  );
};

export default AccountInput;

const styles = StyleSheet.create({
  marginBottom20: {
    marginBottom: 20,
  },

  iconRequired: {
    color: TEXT_COLOR_RED,
    marginLeft: 6,
    fontSize: 16,
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mt5: {
    marginTop: 5,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  textGrey: {
    color: TEXT_COLOR_GREY,
  },
  startIcon: {
    position: 'absolute',
    left: -15,
    top: '25%',
  },
  titleWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
