import { StyleSheet } from 'react-native';
import {
  BG_ACTIVE_SIDEBAR,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_HEADER,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_HEADER_LOGIN,
  TEXT_COLOR_GREY,
  TEXT_COLOR_GREEN,
  BTN_COLOR_GREY,
} from '@assets/style/styleDefault';

const btnAction = {
  fontWeight: 400,
  lineHeight: 1.5,
  cursor: 'pointer',
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'initial',
  height: '55px',
  width: '20vw',
  borderRadius: 4,
};

const styles = StyleSheet.create({
  backgroundColor: {
    backgroundColor: BG_ACTIVE_SIDEBAR,
  },
  backgroundVideo: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    height: '100%',
    backgroundSize: 'cover',
  },
  backgroundImg: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  backGroup: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  loginContent: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },
  container: {
    display: 'block',
    width: '100%',
    backgroundColor: 'white',
    marginHorizontal: 'auto',
    paddingVertical: 10,
    maxWidth: '1320px',
  },
  row: {
    display: ['flex'],
    alignItems: 'center',
  },
  form: {
    backgroundColor: 'transparent',
    position: 'relative',
    paddingRight: '48px',
    paddingLeft: '48px',
    WebkitBoxFlex: '0',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    width: '100%',
    maxWidth: '100%',
    minWidth: '250px',
    marginTop: '6%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
  },
  logo: {
    width: 40,
    height: 40,
    textAlignVertical: 'middle',
  },
  textHeader: {
    fontFamily: FONT_FAMILY_HEADER,
    margin: '0',
    display: 'block',
    marginInlineStart: 0,
    marginInlineEnd: 0,
  },
  colorS: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER_LOGIN,

    fontFamily: FONT_FAMILY_HEADER,
  },
  whiteColor: {
    color: '#fff',
    fontSize: '55px',
    fontWeight: 500,
    fontFamily: FONT_FAMILY_HEADER,
  },
  formBody: {
    paddingTop: 90,
    paddingBottom: '30px',
    maxWidth: 500,
  },

  relative: {
    position: 'relative',
    marginTop: 10,
    marginBottom: 20,
  },
  input: {
    backgroundColor: 'rgba(64,64,64,0.65)',
    height: 50,
    borderRadius: 4,
    paddingLeft: '50px',
    color: '#f2f2f2',
    fontSize: 14,
  },
  iconPrefix: {
    position: 'absolute',
    height: 20,
    width: 20,
    top: 14,
    left: 20,
  },
  iconMessage: {
    height: 15,
    top: 18,
  },
  iconBlock: {
    height: 23,
    width: 19,
    top: 12,
  },
  formLink: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
    fontSize: '14px',
    paddingLeft: '35px',
    paddingTop: '30px',
  },

  imgForget: {
    borderRadius: '50px',
    width: 30,
    height: 30,
  },
  textForget: {
    marginLeft: 10,
    color: COLOR_TEXT_DEFAULT,
    borderBottomWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#7d7f81',
    fontSize: '14px',
    marginBottom: '10px',
  },
  formAction: {
    marginTop: '66px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  buttonAction: {
    ...btnAction,
    backgroundColor: BTN_COLOR_GREY,
    width: 300,
  },
  btnActive: { borderRadius: 5, width: 300 },
  textBtnAction: {
    color: TEXT_COLOR_GREY,
  },
  buttonSubmit: {
    ...btnAction,
    width: 300,
  },
  textBtnSubmit: {
    color: COLOR_TEXT_DEFAULT,
  },
  text: {
    padding: 40,
    minWidth: 120,
    color: COLOR_TEXT_DEFAULT,
    paddingTop: 90,
  },
  textClose: {
    paddingLeft: 10,
    color: TEXT_COLOR_GREEN,
    fontSize: FONT_SIZE_DEFAULT,
  },
  btnClose: {
    flexDirection: 'row',
    zIndex: 9,
    paddingLeft: 20,
    paddingTop: 20,
  },
  iconClose: { width: 18, height: 18 },
  iconKiteWhite: { width: 32, height: 24, marginRight: 10 },
});

export default styles;
