import React, { createElement } from 'react';
import { View, StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';

import { JText as Text } from './JText';
import { COLOR_TEXT_DEFAULT, FONT_FAMILY_DEFAULT, FONT_SIZE_DEFAULT, INPUT_BG } from '@assets/style/styleDefault';

const Input = ({ type, value, min, max, onChange, style, borderError, height = 40, disable }) => {
  return createElement('input', {
    type: type,
    min: min,
    max: max,
    value: value,
    onChange: onChange,
    disabled: !disable,
    style: {
      fontFamily: FONT_FAMILY_DEFAULT,
      height: height,
      fontSize: FONT_SIZE_DEFAULT,
      color: COLOR_TEXT_DEFAULT,
      padding: '0 20px',
      borderRadius: '4px',
      outlineStyle: 'none',
      borderWidth: borderError ? 1 : 0,
      borderColor: borderError,
      backgroundColor: INPUT_BG,
      colorScheme: 'dark',
      maxHeight: 42,
      cursor: 'pointer',
      ...style,
    },
  });
};

const DateTimePicker = (props) => {
  const {
    borderError,
    name,
    control,
    type,
    min,
    max,
    style,
    dateTime = null,
    setDateTime = null,
    height,
    disable = true,
  } = props;
  return (
    <View style={styles.container}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Input
              disable={disable}
              onChange={(e) => {
                setDateTime ? setDateTime(e.target.value) : null;
                onChange(e);
              }}
              value={value}
              type={type}
              min={min ? min : null}
              max={max ? max : null}
              style={style}
              borderError={error?.message ? 'red' : ''}
              height={height}
            />
            <Text style={styles.error}>{error?.message}</Text>
          </>
        )}
      />
    </View>
  );
};

export default DateTimePicker;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  error: {
    position: 'absolute',
    bottom: -20,
    paddingTop: 3,
    color: 'red',
    fontSize: 12,
  },
});
