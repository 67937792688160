import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import formatNumber from '../../helper/formatNumber';
import { JText as Text } from '../common/JText';
import HeaderAvatar from './HeaderAvatar';
import HeaderUserId from './HeaderUserId';

const HeaderTableList = (props) => {
  const { textHeader, textHeaderTitle, textInfoTitle, textInfoValue, data, totalAmountCurrencyPrefix  = '',  totalAmountCurrencySuffix = '' } = props;

  return (
    <>
      <View style={[styles.wrapperHeader]}>
        <View style={styles.leftSearch}>
          <Text style={styles.textHeader}>{textHeader}</Text>
          <Text style={styles.textHeaderTitle}>{`ー　${textHeaderTitle}`}</Text>
        </View>
        <HeaderAvatar />
      </View>
      <HeaderUserId />
      <View style={styles.wrapperInput}>
        <Text style={styles.wrapperTextInfo}>
          {`${textInfoTitle}     `}
          <Text style={styles.textInfo}>
            {data?.pageInfo?.totalCount >= 0 ? `${formatNumber(data?.pageInfo?.totalCount)} 件` : ''}
          </Text>
        </Text>
        <Text style={styles.textInfo}>
          {`${textInfoValue}     `}
          <Text style={styles.textInfo}>
            {data?.totalAmount >= 0 ? totalAmountCurrencyPrefix + ` ${formatNumber(data?.totalAmount)} `+totalAmountCurrencySuffix : ''}
          </Text>
        </Text>
      </View>
      <Text style={styles.textSearch}>期間指定</Text>
    </>
  );
};

export default memo(HeaderTableList);

const styles = StyleSheet.create({
  wrapperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 15,
    paddingHorizontal: 20,
  },
  leftSearch: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  wrapperInput: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    gap: 20,
  },
  textInfo: {
    color: '#D9D9D9',
    paddingRight: 30,
    fontSize: 24,
    paddingBottom: 35,
  },
  wrapperTextInfo: {
    width: 500,
    color: '#D9D9D9',
    paddingRight: 30,
    fontSize: 24,
    paddingBottom: 35,
  },
  textSearch: {
    fontSize: 20,
    paddingHorizontal: 20,
  },
  textHeader: {
    color: '#838383',
    fontSize: 36,
  },
  textHeaderTitle: {
    color: '#A5A5A5',
    paddingLeft: 30,
    fontSize: 18,
  },
});
