import { STORAGEKEY } from '@helper/constant';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (key, value, options) => {
  cookies.set(key, value, { ...options, path: '/' });
};

export const getCookie = (key) => {
  return cookies.get(key);
};

export const logout = () => {
  cookies.remove(STORAGEKEY.ACCESS_TOKEN, { path: '/' });
};

export const reLoadPage = () => {
  window.location.reload();
};
