import React from 'react';
import AccountInput from '@components/AccountComponents/AccountInput';
import { Col, Grid } from 'react-native-easy-grid';

import { JText as Text } from '@components/common/JText';
import styles from '../styles';

export const fullNameData = [
  {
    placeholder: '代表者の姓を入力してください',
    isRequired: true,
    labelText: '姓',
    name: 'merchantUserInformationLastName',
    type: 'DEFAULT',
  },
  {
    placeholder: '代表者の姓カナを入力してください',
    isRequired: true,
    labelText: '姓（カナ）',
    name: 'merchantUserInformationLastNameKana',
    type: 'DEFAULT',
  },
  {
    placeholder: '代表者の名を入力してください',
    isRequired: true,
    labelText: '名',
    name: 'merchantUserInformationFirstName',
    type: 'DEFAULT',
  },
  {
    placeholder: '代表者の名カナを入力してください',
    isRequired: true,
    labelText: '名（カナ）',
    name: 'merchantUserInformationFirstNameKana',
    type: 'DEFAULT',
  },
  {
    placeholder: '代表者の肩書を入力してください',
    isRequired: true,
    labelText: '肩書',
    name: 'merchantUserInformationTitle',
    type: 'DEFAULT',
  },
];

const FullName = ({ control, disable = false }) => {
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>代表者</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      {fullNameData.map((i) => (
        <AccountInput
          disable={disable}
          key={i.name}
          control={control}
          name={i.name}
          placeholder={i.placeholder}
          isRequired={i.isRequired}
          labelText={i.labelText}
          subfix={i.subfix}
          ml={12}
        />
      ))}
    </>
  );
};

export default FullName;
