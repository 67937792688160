import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { NavLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinearGradient } from 'expo-linear-gradient';
import { Video } from 'expo-av';
import moment from 'moment';

import { TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import { setCookie } from '@utils/TokenStorage';
import { TXT_LOGIN_FAIL } from '@helper/stringHelper';
import { post } from '@utils/RequestLevica';
import { API_PATH, STORAGEKEY } from '@helper/constant';
import styles from './style';

const schema = Yup.object().shape({
  email: Yup.string().required(''),
  password: Yup.string().required(''),
});

const Login = () => {
  const [isErrorBackgroundVideo, setErrorBackgroundVideo] = useState(false);

  const history = useHistory();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (dataFrom) => {
    try {
      const { accessToken } = await post(API_PATH.LOGIN, dataFrom);
      if (accessToken) {
        setCookie(STORAGEKEY.ACCESS_TOKEN, accessToken?.token, { expires: moment(accessToken?.expiredAt).toDate() });
        history.push('/');
      }

      history.push('/');
    } catch (error) {
      setError('password', {
        type: 'server',
        message: '',
      });
      setError('email', {
        type: 'server',
        message: '',
      });
      console.error(error?.message);
    }
  };

  return (
    <View style={styles.backgroundColor}>
      {isErrorBackgroundVideo ? (
        <Image style={styles.backgroundImg} source={require('@assets/img/login.jpg')} alt="background login" />
      ) : (
        <Video
          source={require('@assets/videos/login-background.mp4')}
          style={styles.backgroundVideo}
          posterStyle={styles.backgroundImg}
          rate={1}
          shouldPlay={true}
          isLooping={true}
          isMuted={true}
          posterSource={require('@assets/img/login.jpg')}
          usePoster={true}
          onError={() => setErrorBackgroundVideo(true)}
          VideoNaturalSize={{ width: 1200 }}
          resizeMode="cover"
        />
      )}

      <View style={styles.warperTextHeaderAdmin}>
        <Text style={[styles.textHeaderAdmin, { color: TEXT_COLOR_GREEN }]}>
          S<Text style={styles.textHeaderAdmin}>TORE ADMIN</Text>
        </Text>
      </View>
      <View style={styles.loginContent}>
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.form}>
              <View style={styles.formHeader}>
                <View style={styles.textHeader}>
                  <Text style={styles.colorS}>SIGN IN </Text>
                </View>
              </View>
              <View style={styles.formBody}>
                {Object.keys(errors).length ? (
                  <Text style={styles.textRed}>{TXT_LOGIN_FAIL}</Text>
                ) : (
                  <Text style={styles.textRed}>{`  `}</Text>
                )}
                <View style={styles.padding10}>
                  <JInput
                    placeholder="Please enter right email address"
                    width="100%"
                    prefix="mailGray.svg"
                    widthPrefix={22}
                    heightPrefix={16}
                    topPrefix={18}
                    control={control}
                    name="email"
                    borderError={errors.email ? '#FF0000' : '#5C5C5C'}
                    heightInput={52}
                    onPressEnter={handleSubmit(onSubmit)}
                  />
                </View>
                <View style={styles.padding10}>
                  <JInput
                    placeholder="Please enter your password."
                    width="100%"
                    prefix="lock.svg"
                    widthPrefix={19}
                    heightPrefix={23}
                    topPrefix={11}
                    control={control}
                    secureTextEntry={true}
                    name="password"
                    borderError={errors.password ? '#FF0000' : '#5C5C5C'}
                    heightInput={52}
                    fontSize={42}
                    onPressEnter={handleSubmit(onSubmit)}
                  />
                </View>

                <View style={styles.formLink}>
                  <NavLink
                    to={'/forgot-password/address'}
                    style={{ textDecorationLine: 'none', display: 'flex', flexDirection: 'row' }}
                  >
                    <Image style={styles.imgForget} source={require(`@assets/img/pass.png`)} alt="pass" />
                    <Text style={styles.textForget}>If you forget your password.</Text>
                  </NavLink>
                  <NavLink
                    to={'/forgot-password/phone'}
                    style={{ textDecorationLine: 'none', display: 'flex', flexDirection: 'row' }}
                  >
                    <Image style={styles.imgForget} source={require(`@assets/img/mail.png`)} alt="mail" />
                    <Text style={styles.textForget}>If you forget your email address.</Text>
                  </NavLink>
                </View>
                <View style={styles.formAction}>
                  {isDirty & isValid ? (
                    <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
                      <TouchableOpacity
                        onPress={() => {
                          handleSubmit(onSubmit)();
                        }}
                      >
                        <View style={[styles.buttonSubmit]}>
                          <View style={styles.buttonSubmit}>
                            <Text>Sign in</Text>
                          </View>
                          <View style={styles.signInLine} />
                        </View>
                      </TouchableOpacity>
                    </LinearGradient>
                  ) : (
                    <View style={styles.buttonAction}>
                      <Text style={styles.textBtnAction}>Sign in</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.warperTextHeaderBottom}>
        <Text style={styles.textInc}>© LEVIAS Inc. All rights reserved.</Text>
      </View>
    </View>
  );
};

export default Login;
