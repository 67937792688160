import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { NavLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Video } from 'expo-av';
import { LinearGradient } from 'expo-linear-gradient';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import { TXT_REQUIRED, JP_PHONE, OTHER_ERROR, WRONG_PHONE_NUMBER } from '@helper/stringHelper';
import { JText as Text } from '@components/common/JText';
import { post } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import styles from './style';
import PhoneInput from '@components/common/PhoneInput';

const ForgotEmail = () => {
  const [isErrorBackgroundVideo, setErrorBackgroundVideo] = useState(false);
  const history = useHistory();

  const schema = Yup.object().shape({
    mobileNumber: Yup.string()
      .trim()
      .nullable()
      .test('compare ', JP_PHONE, (phone) => {
        if (phone && parsePhoneNumber(phone)?.nationalNumber) {
          return isValidPhoneNumber(phone);
        } else {
          return true;
        }
      })
      .required(TXT_REQUIRED),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      mobileNumber: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (phoneNumber) => {
    if (phoneNumber.mobileNumber?.length < 6) {
      setError('mobileNumber', {
        type: 'custom',
        message: JP_PHONE,
      });
      return;
    }
    try {
      await post(API_PATH.FORGOT_EMAIL, phoneNumber);
      history.push('/forgot-password/phone/completed');
    } catch (error) {
      if (error?.response?.data?.code === '2000') {
        setError('mobileNumber', {
          type: 'server',
          message: WRONG_PHONE_NUMBER,
        });
      } else {
        setError('mobileNumber', {
          type: 'server',
          message: OTHER_ERROR,
        });
      }
      console.error(error?.response?.data.message);
    }
  };

  return (
    <>
      <View style={styles.backgroundColor}>
        {isErrorBackgroundVideo ? (
          <Image style={styles.backgroundImg} source={require('@assets/img/login.jpg')} alt="background login" />
        ) : (
          <Video
            source={require('@assets/videos/login-background.mp4')}
            style={styles.backgroundVideo}
            posterStyle={styles.backgroundImg}
            rate={1}
            shouldPlay={true}
            isLooping={true}
            isMuted={true}
            posterSource={require('@assets/img/login.jpg')}
            usePoster={true}
            onError={() => setErrorBackgroundVideo(true)}
            VideoNaturalSize={{ width: 1200 }}
            resizeMode="cover"
          />
        )}
        <View style={styles.backGroup}>
          <NavLink to={'/login'} style={{ textDecorationLine: 'none' }}>
            <View style={styles.btnClose}>
              <Image style={styles.iconClose} source={require('@assets/icons/closeGreen.svg')} />
              <View>
                <Text style={styles.textClose}>キャンセル</Text>
              </View>
            </View>
          </NavLink>
          <View style={styles.form}>
            <View style={styles.formHeader}>
              <View style={styles.textHeader}>
                <Text style={styles.colorS}>携帯電話番号認証</Text>
              </View>
            </View>
            <View style={styles.formBody}>
              <PhoneInput
                name="mobileNumber"
                control={control}
                borderRadius={5}
                paddingLeft={10}
                textErrorPosition="initial"
              />
              <View>
                <Text style={styles.text}>
                  メールアドレスをSMSでお知らせします。登録済の携帯電話番 号を入力してください。
                </Text>
              </View>
              <View style={styles.formAction}>
                {isDirty ? (
                  <LinearGradient colors={['#54DBB2', '#54DBB2']} style={styles.btnActive}>
                    <TouchableOpacity
                      onPress={() => {
                        handleSubmit(onSubmit)();
                      }}
                    >
                      <View style={styles.buttonSubmit}>
                        <Image source={require('@assets/icons/kiteWhite.svg')} style={styles.iconKiteWhite} />
                        <Text style={styles.textBtnSubmit}>SMSを送信</Text>
                      </View>
                    </TouchableOpacity>
                  </LinearGradient>
                ) : (
                  <View style={styles.buttonAction}>
                    <Image source={require('@assets/icons/kiteGray.svg')} style={styles.iconKiteWhite} />
                    <Text style={styles.textBtnAction}>SMSを送信</Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default ForgotEmail;
