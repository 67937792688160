import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';

import JInput from '@components/common/JInput';
import AccountInput from '@components/AccountComponents/AccountInput';
import { JText as Text } from '@components/common/JText';
import styles from '../styles';

const TransactionContactForm = ({ control, disable = false }) => {
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>取引担当者住所</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <Text style={[styles.colorWhite, styles.marginLeft12]}>郵便番号</Text>
          <Text style={styles.iconRequired}>✴︎</Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative' }}>
              <Text style={[styles.startIcon, styles.colorWhite]}>〒</Text>
              <JInput
                disable={disable}
                control={control}
                name="traderInformationPostCode"
                placeholder="未登錄"
                widthSubfix={15}
                heightSubfix={15}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}>
              <Text style={[styles.marginLeft8, styles.colorGray]}>※ハイフン不要 </Text>
            </Col>
          </Grid>
        </Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <Text style={[styles.colorWhite, styles.marginLeft12]}>都道府県 </Text>
          <Text style={styles.iconRequired}>✴︎</Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative' }}>
              <JInput
                disable={disable}
                control={control}
                name="traderInformationPrefecture"
                placeholder="未登錄"
                widthSubfix={15}
                heightSubfix={15}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}></Col>
          </Grid>
        </Col>
      </Grid>

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationCity"
        placeholder="市町村を入力してください"
        isRequired={true}
        labelText="市町村 "
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationAddress"
        placeholder="番地をを入力してください"
        isRequired={true}
        labelText="番地 "
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationBuilding"
        placeholder="建物名を入力してください"
        isRequired={true}
        labelText="建物名"
        ml={12}
      />

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <Text style={[styles.colorWhite, styles.marginLeft12]}>階数・号室 </Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={4} style={{ position: 'relative' }}>
              <JInput
                disable={disable}
                control={control}
                name="traderInformationFloor"
                placeholder="--"
                widthSubfix={15}
                heightSubfix={15}
              />
            </Col>
            <Col size={2} style={styles.flex}>
              <Text style={[styles.marginLeft8]}>階 </Text>
            </Col>
            <Col size={4} style={{ position: 'relative' }}>
              <JInput
                disable={disable}
                control={control}
                name="traderInformationRoom"
                placeholder="--"
                widthSubfix={15}
                heightSubfix={15}
              />
            </Col>
            <Col size={2} style={styles.flex}>
              <Text style={[styles.marginLeft8]}>号室</Text>
            </Col>
          </Grid>
        </Col>
      </Grid>
    </>
  );
};

export default TransactionContactForm;
