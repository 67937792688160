import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-native-modal';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';

import { JText as Text } from '../common/JText';
import { logout } from '@utils/TokenStorage';
import { post } from '@utils/RequestLevica';
import { clearUser } from '@redux/slices/authSlice';
import { API_PATH } from '@helper/constant';
import ModalBackDrop from './ModalBackDrop';

const ModalLogout = ({ isModal, setIsModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutPost = async () => {
    try {
      await post(API_PATH.LOGOUT);
    } catch (error) {
      console.error(error.massage);
    }
  };
  useEffect(() => {
    logoutPost();
  }, []);

  const handleLogout = () => {
    logout();
    dispatch(clearUser());
    setIsModal(false);
    history.push('/login');
  };
  return (
    <Modal
      backdropTransitionOutTiming={1}
      animationOutTiming={1}
      animationInTiming={1}
      isVisible={isModal}
      style={styles.modal}
      customBackdrop={<ModalBackDrop onPress={handleLogout} />}
    >
      <TouchableOpacity onPress={() => handleLogout()}>
        <View style={styles.warper}>
          <Image style={styles.iconLogout} source={require(`@assets/icons/logout-gray.svg`)} />
          <Text style={styles.text}>{`サインアウトしました　${moment().format('YYYY.MM.DD HH:mm')}`}</Text>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  //common
  modal: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    margin: 0,
  },
  warper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 150,
    paddingVertical: 25,
    backgroundColor: 'white',
    height: 100,
  },
  iconLogout: {
    width: 28,
    height: 22,
    marginRight: 30,
  },
  text: {
    color: '#191919',
    fontSize: 19,
  },
});
export default ModalLogout;
