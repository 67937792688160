import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import formatNumber from '../../helper/formatNumber';
import { JText as Text } from '../common/JText';
import HeaderAvatar from './HeaderAvatar';
import HeaderUserId from './HeaderUserId';
import { ScrollView } from 'native-base';

const HeaderMerchantRefundAmount = (props) => {
  const { textHeader, textHeaderTitle, textInfoTitle, data , textInfoDateRange} = props;

  return (
    <>
      <View style={[styles.wrapperHeader]}>
          <ScrollView horizontal>
            <View style={styles.leftSearch}>
                <Text style={styles.textHeader}>{textHeader}</Text>
                <Text style={styles.textHeaderTitle}>{`ー　${textHeaderTitle}`}</Text>
            </View>
          </ScrollView>
        <HeaderAvatar />
      </View>
      <HeaderUserId />
      <View style={styles.wrapperInput}>
        <Text style={styles.wrapperTextInfo}>
          {`${formatNumber(textInfoTitle)}  円`}
        </Text>
        <Text style={styles.textLabel}>
            対象期間
        </Text>
        <Text style={styles.textInfo}>
            {textInfoDateRange}
        </Text>
      </View>
    </>
  );
};

export default memo(HeaderMerchantRefundAmount);

const styles = StyleSheet.create({
  wrapperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 15,
    paddingHorizontal: 20,
  },
  leftSearch: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  wrapperInput: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    gap: 20,
    paddingBottom: 35,
  },
  textInfo: {
    color: '#D9D9D9',
    padding: 15,
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 17,
    backgroundColor: "#404040",
    borderRadius: 5,
  },
  wrapperTextInfo: {
    width: 500,
    color: '#D9D9D9',
    paddingRight: 30,
    fontSize: 24,
  },
  textSearch: {
    fontSize: 20,
    paddingHorizontal: 20,
  },
  textHeader: {
    fontFamily: 'BebasNeue',
    color: '#838383',
    fontSize: 36,
  },
  textHeaderTitle: {
    color: '#A5A5A5',
    paddingLeft: 30,
    fontSize: 18,
  },
  textLabel: {
    color: '#D9D9D9',
    fontSize: 17,
  }
});
