import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { View, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { Grid, Col } from 'react-native-easy-grid';
import moment from 'moment';

import { BG_SIDEBAR, INPUT_BG } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';

import ModalImagePreview from '@components/Modals/ModalImagePreview';

const statusData = [
  {
    id: 1,
    text: '稼働中',
    icon: 'circleStatus0.svg',
    color: '#00be85',
  },
  {
    id: 2,
    text: 'メールアドレス登録完了',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 3,
    text: '携帯電話番号登録完了',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 4,
    text: '会員情報入力完了',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 5,
    text: '会員情報入力完了・承認待ち',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 6,
    text: '会員登録情報補正・承認待ち',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 7,
    text: '本人確認業務NG・再提出依頼中',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 8,
    text: '本人確認業務NG・個別対応中',
    icon: 'circleStatus3.svg',
    color: '#3B00FF',
  },
  {
    id: 9,
    text: 'マイナンバーNG・再提出依頼中',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },

  {
    id: 10,
    text: 'マイナンバー補正・承認待ち',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 11,
    text: 'アカウント情報変更申請中',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 12,
    text: 'アカウント一時停止',
    icon: 'circleStatus4.svg',
    color: '#FF0000',
  },
  {
    id: 13,
    text: 'アカウント永久停止',
    icon: 'circleStatus4.svg',
    color: '#FF0000',
  },
];

const BankInfo = ({ formValue }) => {
  const [currentStatus, setCurrentStatus] = useState(statusData[0]);
  const history = useHistory();
  const [modalIdentityVerification, setModalIdentityVerification] = useState(false);
  const [modalNumberCard, setOpenModalNumberCard] = useState(false);
  const [modalWallet, setModalWallet] = useState(false);
  const [modalPaymentQr, setModalPaymentQr] = useState(false);

  return (
    <View style={styles.warperBank}>
      <Grid style={{ marginBottom: 10 }}>
        <Col size={5}>
          <Text style={styles.textWhite}>アカウント有効開始日</Text>
        </Col>
        <Col size={7} style={styles.textRight}>
          <Text style={{ color: '#BCBCBC' }}>{moment(formValue?.createdAt).format('YYYY.MM.DD')}</Text>
        </Col>
      </Grid>

      <View style={[styles.row, styles.bankInfoDetails]}>
        <Text style={styles.textWhite}>アカウントステータス</Text>
        <Text style={{ color: currentStatus.color, paddingLeft: 25 }} numberOfLines={2}>
          稼働中
        </Text>
        <Image style={styles.subfix} source={require('@assets/icons/iconShare.svg')} />
      </View>

      <Grid style={styles.minHeight}>
        <Col size={6}>
          {/* <TouchableOpacity onPress={() => setModalIdentityVerification(true)}> */}
          <TouchableOpacity>
            <LinkBankInfo text="本人確認書類" />
          </TouchableOpacity>
        </Col>
        <Col size={6}>
          {/* <TouchableOpacity onPress={() => setOpenModalNumberCard(true)}> */}
          <TouchableOpacity>
            <LinkBankInfo text="マイナンバーカード" />
          </TouchableOpacity>
        </Col>
      </Grid>

      <Grid style={{ gap: 10 }}>
        <Col size={6}>
          <TouchableOpacity onPress={() => setModalWallet(true)}>
            <LinkBankInfo text="Wallet QRコード" />
          </TouchableOpacity>
        </Col>
        <Col size={6}>
          <TouchableOpacity onPress={() => setModalPaymentQr(true)}>
            <LinkBankInfo text="決済用 QRコード" />
          </TouchableOpacity>
        </Col>
      </Grid>
      <Grid style={{ gap: 10, marginTop: 25 }}>
        <Col size={6}>
          <TouchableOpacity onPress={() => history.push(`/shop/details/bank`)}>
            <LinkBankInfo text="銀行口座情報" />
          </TouchableOpacity>
        </Col>
        <Col size={6}></Col>
      </Grid>

      {modalIdentityVerification && (
        <ModalImagePreview
          name="本人確認書類"
          cardUrl
          isModal={modalIdentityVerification}
          setIsModal={setModalIdentityVerification}
        />
      )}
      {modalNumberCard && (
        <ModalImagePreview
          name="マイナンバーカード"
          cardUrl
          isModal={modalNumberCard}
          setIsModal={setOpenModalNumberCard}
        />
      )}
      {modalWallet && (
        <ModalImagePreview
          name="Wallet QRコード"
          qrCode={formValue.walletQr}
          isModal={modalWallet}
          setIsModal={setModalWallet}
        />
      )}
      {modalPaymentQr && (
        <ModalImagePreview
          name="決済用 QRコード"
          qrCode={formValue.paymentQr}
          isModal={modalPaymentQr}
          setIsModal={setModalPaymentQr}
        />
      )}
    </View>
  );
};

export default BankInfo;

const LinkBankInfo = ({ text }) => {
  return (
    <View style={[styles.row, styles.bankInfoDetails, styles.marginBottom20]}>
      <Text numberOfLines={2}>{text}</Text>
      <Image style={styles.subfix} source={require('@assets/icons/iconShare.svg')} />
    </View>
  );
};

const styles = StyleSheet.create({
  warperBank: { backgroundColor: BG_SIDEBAR, padding: 20, borderRadius: 5, marginBottom: 20, paddingBottom: 30 },
  textRight: {
    textAlign: 'right',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: INPUT_BG,
    border: '1px solid #555555',
    padding: 20,
    borderRadius: 5,
  },
  bankInfoDetails: {
    height: 42,
    backgroundColor: INPUT_BG,
    borderRadius: 3,
    paddingLeft: 15,
    paddingRight: 35,
    position: 'relative',
  },

  subfix: {
    position: 'absolute',
    width: 15,
    height: 15,
    top: '30%',
    right: 10,
  },

  minHeight: {
    minHeight: 'unset',
    gap: 10,
    marginVertical: 20,
  },
});
