import { API_PATH } from '@helper/constant';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from '@utils/RequestLevica';

export const merchantUserInfo = createAsyncThunk('getMerchantUserInfo', async () => {
  const data = await get(API_PATH.HEARD_INFO);
  return data;
});

const initialState = null;
const userSlice = createSlice({
  name: 'merchantUserInfo',
  initialState,
  reducers: {
    clearUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(merchantUserInfo.fulfilled, (state, action) => {
      return (state = action.payload);
    });
  },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
