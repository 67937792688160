import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import formatNumber from '../../helper/formatNumber';
import { JText as Text } from '../common/JText';
import HeaderAvatar from './HeaderAvatar';
import HeaderUserId from './HeaderUserId';
import styles from '../../pages/PaymentHistory/style';

const HeaderBillingHistory = (props) => {
  const { textHeader, textHeaderTitle, textInfoTitle, textInfoValue, data, totalAmountCurrencyPrefix  = '',  totalAmountCurrencySuffix = '', onPressCSVExport } = props;

  return (
    <>
      <View style={[localStyles.wrapperHeader]}>
        <View style={localStyles.leftSearch}>
          <Text style={localStyles.textHeader}>{textHeader}</Text>
          <Text style={localStyles.textHeaderTitle}>{`ー　${textHeaderTitle}`}</Text>
        </View>
        <HeaderAvatar />
      </View>
      <HeaderUserId />
      <View style={[localStyles.wrapperInput]}>
        <Text style={localStyles.wrapperTextInfo}>
          {`${textInfoTitle}     `}
          <Text style={localStyles.textInfo}>
            {data?.pageInfo?.totalCount >= 0 ? `${formatNumber(data?.pageInfo?.totalCount)} 件` : ''}
          </Text>
        </Text>
        <Text style={localStyles.textInfo}>
          {`${textInfoValue}     `}
          <Text style={localStyles.textInfo}>
            {data?.totalAmount >= 0 ? totalAmountCurrencyPrefix + ` ${formatNumber(data?.totalAmount)} `+totalAmountCurrencySuffix : ''}
          </Text>
        </Text>
        <Text style={[styles.CsvActive, { textDecoration: 'underline' }]} onPress={onPressCSVExport}>
            CSVファイル出力
        </Text>
      </View>
    </>
  );
};

export default memo(HeaderBillingHistory);

const localStyles = StyleSheet.create({
  wrapperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 15,
    paddingHorizontal: 20,
  },
  leftSearch: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  wrapperInput: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    gap: 20,
  },
  textInfo: {
    color: '#D9D9D9',
    paddingRight: 30,
    fontSize: 24,
  },
  wrapperTextInfo: {
    color: '#D9D9D9',
    paddingRight: 10,
    fontSize: 24,
    paddingRight: 50
  },
  textSearch: {
    fontSize: 20,
    paddingHorizontal: 20,
  },
  textHeader: {
    color: '#838383',
    fontFamily: 'BebasNeue',
    fontSize: 36,
  },
  textHeaderTitle: {
    color: '#A5A5A5',
    paddingLeft: 30,
    fontSize: 18,
  },
});
