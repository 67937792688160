import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LinearGradient } from 'expo-linear-gradient';
import { CSVLink } from 'react-csv';

import formatNumber from '@helper/formatNumber';
import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import { BG_TABLE_DEFAULT, INPUT_BG, LINE_COLOR, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import DateTimePicker from '@components/common/DateTimePicker';
import { downloadCSV, get } from '@utils/RequestLevica';
import HeaderBillingHistory from '@components/Headers/HeaderBillingHistory';
import { API_PATH } from '@helper/constant';
import styles from './style';

const defaultValues = {
  startDate: '',
  endDate: '',
};
const validationSchema = Yup.object().shape({
  startDate: Yup.string(),
  endDate: Yup.string(),
});
const tableHeadData = [
  {
    title: '取引番号',
    size: 3,
  },
  {
    title: '対象決済金額',
    hasIcon: true,
    sort: '0200',
    order: false,
    size: 3,
  },
  {
    title: '払戻請求設定金額',
    hasIcon: true,
    sort: '0100',
    order: false,
    size: 3,
  },
  {
    title: '入金金額',
    hasIcon: true,
    sort: '0300',
    order: false,
    size: 3,
  },
  {
    title: '対象期間',
    hasIcon: true,
    sort: '0400',
    order: false,
    size: 3,
  },

  {
    title: 'ステータス',
    hasIcon: true,
    sort: '0500',
    order: false,
    size: 3,
  },
];

const csvHeader = [
  {
    label: '取引番号',
    key: 'id',
  },
  {
    label: '払戻請求設定金額',
    key: 'setting_amount',
  },
  {
    label: '振込日',
    key: 'refundedAt',
  },

  {
    label: '対象決済金額',
    key: 'target_amount',
  },
  {
    label: '入金金額',
    key: 'amount',
  },
  {
    label: '対象期間',
    key: 'refund_start_date',
  },
  {
    label: 'ステータス',
    key: 'status',
  },
];

const csvDataDefault = [
  {
    id: '',
    refundedAt: '',
    amount: '',
  },
];

const BillingHistory = () => {
  const [pageExport, setPageExport] = useState([]);
  const [itemCheck, setItemCheck] = useState([]);
  const [exportAll, setExportAll] = useState(false);
  const [dataCSVExport, setDataCSVExport] = useState(null);

  const [billingHistoryTable, setBillingHistoryTable] = useState([]);
  const [billingHistoryDataGet, setBillingHistoryDataGet] = useState(null);

  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    startDate: '',
    endDate: '',
    sort: '0200',
    order: 'desc',
  });

  const selectAll = async () => {
    const listChecked = billingHistoryTable?.filter((item) => {
      return item.pickup === true;
    });
    const newListData = billingHistoryTable?.map((item) => ({
      ...item,
      pickup: listChecked?.length > 0 ? (listChecked?.length === billingHistoryTable?.length ? false : true) : true,
    }));
    setBillingHistoryTable(newListData);
    setExportAll(!exportAll);
  };

  const toggleCheckbox = (item, index) => {
    if (exportAll) {
      setExportAll(false);
    }
    const newListData = [...billingHistoryTable];
    newListData?.splice(index, 1, { ...item, pickup: !item.pickup });
    setBillingHistoryTable(newListData);
    if (pageExport.indexOf(params.pageNumber) < 0) {
      setPageExport([...pageExport, params.pageNumber]);
    }
  };

  const saveItemCheck = () => {
    const listCheckedTrue = billingHistoryTable?.filter((item) => {
      return item.pickup === true;
    });
    const idOfListCheckedTrue = listCheckedTrue.map((item) => item.id);
    const listCheckedFalse = billingHistoryTable?.filter((item) => {
      return item.pickup === false;
    });
    const idOfListCheckedFalse = listCheckedFalse.map((item) => item.id);
    newItemCheck = itemCheck.filter((itemRemove) => !idOfListCheckedFalse.includes(itemRemove));
    setItemCheck([...new Set([...newItemCheck, ...idOfListCheckedTrue])]);
  };

  const handleExportCSV = async () => {
      await downloadCSV(API_PATH.SUM_REFUND_HISTORY_EXPORT_CSV, '全請求一覧', params);
  };

  useEffect(() => {
    const getTransactionHistory = async () => {
      const data = await get(API_PATH.SUM_REFUND_HISTORY_LIST, params);
      setBillingHistoryDataGet(data);
      if (pageExport.includes(params.pageNumber)) {
        const newData = data?.sumRefundHistoryList?.map((item) => ({
          ...item,
          pickup: itemCheck?.includes(item?.id) ? true : false,
        }));
        setBillingHistoryTable(newData);
      } else {
        const newData = data?.sumRefundHistoryList?.map((item) => ({
          ...item,
          pickup: exportAll ? true : false,
        }));
        setBillingHistoryTable(newData);
      }
    };
    getTransactionHistory();
  }, [params]);

  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    const newParams = {
      ...params,
      startDate: moment(data.startDate).format('YYYY-MM-DD'),
      endDate: moment(data.endDate).format('YYYY-MM-DD'),
      pageNumber: 1,
    };
    setParams(newParams);
  };

  const handleSort = (item) => {
    tableHeadData.forEach((i) => {
      if (item.sort === i.sort) {
        i.order = !i.order;
      } else {
        i.order = false;
      }
    });
    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: item.order ? 'asc' : 'desc',
    });
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.wrapperStickyHeader}>
          <HeaderBillingHistory
            textHeader="全請求履歴"
            textHeaderTitle="LEVIAS社に対する、これまでの全払い戻し請求の履歴です。"
            textInfoTitle="全請求履歴件数"
            textInfoValue="累計"
            data={billingHistoryDataGet}
            onPressCSVExport={handleExportCSV}
          />

          <Grid>
            <Col size={10} style={{ minWidth: 800 }}>
              <Grid style={[styles.head, { maxWidth: 1267}]} size={50}>
                {tableHeadData.map((item) => (
                  <Col
                    size={item.size}
                    style={[
                      styles.alignItemsCenter,
                      {
                        visibility: billingHistoryTable?.length > 0 ? 'visible' : 'hidden',
                      },
                    ]}
                    key={item.title}
                  >
                    <Text numberOfLines={2} style={styles.textHead}>
                      {item.title}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => handleSort(item)}
                        style={item.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`@assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            </Col>
            <Col size={2} />
          </Grid>
        </View>
        <Grid>
          {billingHistoryTable?.length === 0 ? (
            <Col  size={10} style={styles.tableNoData}>
              <Text style={styles.text}>{'請求履歴がありません。'}</Text>
            </Col>
          ) : (
            <Col  size={10}  style={{ backgroundColor: BG_TABLE_DEFAULT, minWidth: 800, maxWidth: 1267 }}>
              <ScrollView showsVerticalScrollIndicator={false} >
                {billingHistoryTable?.map((item, index, { length }) => {
                  return (
                    <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                      <Grid style={[styles.headBody, { borderBottomWidth: index + 1 === length ? 0 : 1 }]} size={30}>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.id}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text16} numberOfLines={2}>
                            {item.settingAmountFormat}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text16} numberOfLines={2}>
                            {item.requestedAmountFormat}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={[styles.text16, (item.amount < item.requestedAmount) && styles.redText]} numberOfLines={2}>
                            {item.amountFormat}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text numberOfLines={2} style={styles.text16}>
                            {item?.formattedPaymentStartDate} - {item?.formattedPaymentEndDate}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text numberOfLines={2} style={[styles.text16]}>
                            <Text style={{color: item?.status.color}}>{item?.status.label}</Text>
                          </Text>
                        </Col>
                      </Grid>
                    </Pressable>
                  );
                })}
                <View>
                  <Pagination
                    count={Math.ceil(billingHistoryDataGet?.pageInfo?.totalCount / params.limit)}
                    params={params}
                    setParams={setParams}
                    saveItemCheck={saveItemCheck}
                  />
                </View>
              </ScrollView>
            </Col>
          )}
          <Col size={2} />
        </Grid>
      </View>
    </>
  );
};

export default BillingHistory;
