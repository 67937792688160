import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';

import AccountInput from '@components/AccountComponents/AccountInput';
import { JText as Text } from '@components/common/JText';
import styles from '../styles';

const ContactForm = ({ control, disable = false }) => {
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>連絡先</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <AccountInput
        disable={disable}
        control={control}
        name="email"
        placeholder="連絡用のメール アドレスを入力してください"
        isRequired={true}
        labelText="メールアドレス "
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="phone"
        placeholder="携帯 電話 番号を入力してください"
        isRequired={true}
        labelText="携帯電話番号SMS "
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="landlineNumber"
        placeholder="電話 番号を入力してください"
        isRequired={true}
        labelText="固定電話番号"
        ml={12}
      />
    </>
  );
};

export default ContactForm;
