import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, SectionList, ScrollView, Pressable, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  BG_HOVER,
  BG_ACTIVE_SIDEBAR,
  COLOR_TEXT_DEFAULT,
  BG_SIDEBAR,
  FONT_FAMILY_DEFAULT,
  BTN_COLOR_GREY,
  BG_COLOR,
  TEXT_COLOR_GREEN,
  FONT_SIZE
} from '@assets/style/styleDefault';
import { merchantUserInfo } from '@redux/slices/authSlice';
import { JText as Text } from '../common/JText';
import ModalLogout from '../Modals/ModalLogout';
import { STORAGEKEY } from '@helper/constant';
import { getCookie } from '@utils/TokenStorage';

const dataSiderbar = [
  {
    title: '',
    data: [
      {
        icon: 'home',
        widthIcon: 23,
        heightIcon: 22,
        text: ' ダッシュボード',
        link: '/',
        exact: true,
      },
      {
        icon: 'paymentHistory',
        widthIcon: 28,
        heightIcon: 20,
        text: '決済履歴',
        link: '/payment-history',
      },
      {
        icon: 'requestingRefund',
        widthIcon: 28,
        heightIcon: 28,
        text: '払戻請求中',
        link: '/requesting-refund',
      },
      {
        icon: 'billingHistory',
        widthIcon: 21,
        heightIcon: 23,
        text: ' 全請求履歴',
        link: '/billing-history',
      },
      {
        icon: 'yenRecycled',
        widthIcon: 28,
        heightIcon: 23,
        text: '再利用設定',
        link: '/merchant-refund-amount',
      },
      {
        icon: 'shop',
        widthIcon: 28,
        heightIcon: 23,
        text: '加盟店アカウント',
        link: '/shop/details',
      },
    ],
  },
];

export default function Sidebar() {
  const [isModalLogout, setIsModalLogout] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = getCookie(STORAGEKEY.ACCESS_TOKEN) ?? null;
    if (token) {
      dispatch(merchantUserInfo());
    }
  }, []);

  return (
    <View style={styles.show}>
      <NavLink to="/" style={{ textDecoration: 'none' }}>
        <View style={styles.sidebarHead}>
          <Image source={require('@assets/img/levicaLogo.svg')} style={styles.storeLogo} alt="" />

          <Text style={[styles.textHeader, { color: TEXT_COLOR_GREEN }]}>
            S<Text style={styles.textHeader}>TORE ADMIN</Text>
          </Text>
        </View>
      </NavLink>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.sidebarContent}>
        <SectionList
          sections={dataSiderbar}
          renderItem={({ item }) => (
            <View>
              <View>
                <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
                  <TouchableOpacity onPress={item?.action}>
                    <NavLink
                      exact={item?.exact}
                      to={item.link}
                      activeStyle={{
                        backgroundColor: `${BG_ACTIVE_SIDEBAR}`,
                        textDecorationLine: 'none',
                      }}
                      style={{ textDecorationLine: 'none' }}
                    >
                      <View style={styles.siderbarItem}>
                        <View style={styles.sidebarItemIcon}>
                          <Image
                            source={require(`../../assets/icons/${item.icon}.svg`)}
                            style={
                              item.subIcon
                                ? styles.hasSubIconSidebar
                                : (styles.logoSiderbar, { width: item.widthIcon, height: item.heightIcon })
                            }
                          ></Image>
                        </View>

                        <Text style={styles.sidebarItemTitle}>{item.text}</Text>
                      </View>
                    </NavLink>
                  </TouchableOpacity>
                </Pressable>
              </View>
            </View>
          )}
          renderSectionHeader={({ section }) => <Text style={styles.sectionHeader}>{section.title}</Text>}
          keyExtractor={(item, index) => index}
          style={styles.itemRender}
        />

        <View style={styles.warperLogout}>
          <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
            <TouchableOpacity onPress={() => setIsModalLogout(true)}>
              <View style={styles.siderbarItem}>
                <View style={styles.sidebarItemIcon}>
                  <Image source={require(`@assets/icons/logout.svg`)} style={styles.logoSiderbar} alt="" />
                </View>
                <Text style={styles.sidebarItemTitle}>サインアウト</Text>
              </View>
            </TouchableOpacity>
          </Pressable>
          <Text style={styles.textCopyRight}>© LEVIAS inc. All rights reserved.</Text>
        </View>
      </ScrollView>
      {isModalLogout && <ModalLogout isModal={isModalLogout} setIsModal={setIsModalLogout} />}
    </View>
  );
}

const styles = StyleSheet.create({
  show: {
    flex: '0 0 90px',
    maxWidth: '360px',
    minWidth: '260px',
    width: 360,
    backgroundColor: BG_SIDEBAR,
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '100vh',
    position: 'relative',
  },
  sidebarItemTitle: {
    fontSize: 18,
  },
  buttonHovered: {
    backgroundColor: BTN_COLOR_GREY,
  },
  buttonHoveredLogout: {
    backgroundColor: BG_HOVER,
  },
  sidebarHead: {
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundColor: BG_COLOR,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    paddingLeft: 30,
  },
  textHeader: {
    fontFamily: 'BebasNeue',
    color: '#838383',
    fontSize: '39px',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  storeLogo: {
    width: 36,
    height: 31,
    marginRight: 10,
    marginBottom: 5,
  },
  sidebarContent: {
    height: '100vh',
    backgroundColor: BG_SIDEBAR,
    flexGrow: 0,
    overflow: 'scroll',
  },

  sectionHeader: {
    fontSize: '15px',
    paddingLeft: '30px ',
    paddingRight: '30px',
    paddingBottom: '8px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: '15px',
    marginBottom: 0,
    fontWeight: 500,
    backgroundColor: BG_SIDEBAR,
    color: COLOR_TEXT_DEFAULT,
  },
  itemRender: {
    display: 'flex',
    height: 'calc(100vh - 200px)',
  },

  siderbarItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: ' 30px',
    paddingTop: '11px',
    paddingBottom: '11px',
  },
  sidebarItemIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  logoSiderbar: {
    width: 28,
    height: 22,
    marginRight: 10,
  },
  hasSubIconSidebar: {
    minWidth: '20px',
    minHeight: '20px',
    display: 'block',
    position: 'relative',
    top: '2px',
  },
  sidebarItemTitle: {
    paddingLeft: '20px',
    fontSize: FONT_SIZE,
    width: '100%',
    textDecorationLineLine: 'none',
    color: COLOR_TEXT_DEFAULT,
    letterSpacing: '-1px',
  },
  textCopyRight: { fontSize: 13, textAlign: 'center', paddingTop: 50 },
});
