export {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  NavLink,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
