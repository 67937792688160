import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';

import AccountInput from '@components/AccountComponents/AccountInput';
import { JText as Text } from '@components/common/JText';
import styles from '../styles';

const TransactionPersonForm = ({ control, disable = false }) => {
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>取引担当者</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationDepartment"
        placeholder="取引責任者の所属部署名を入力してください"
        isRequired={true}
        labelText="所属部署"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationDirector"
        placeholder="取引責任者の役職を入力してください"
        isRequired={true}
        labelText="役職 "
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationLastName"
        placeholder="取引責任者の姓を入力してください"
        isRequired={true}
        labelText="姓"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationLastNameKana"
        placeholder="取引責任者の姓カナを入力してください"
        isRequired={true}
        labelText="姓（カナ） "
        ml={12}
      />
      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationFirstName"
        placeholder="取引責任者の名を入力してください"
        isRequired={true}
        labelText="名"
        ml={12}
      />
      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationFirstNameKana"
        placeholder="取引責任者の名カナを入力してください"
        isRequired={true}
        labelText="名（カナ)"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationPhoneNumber"
        placeholder="取引責任者の携帯電話番号を入力してください"
        isRequired={true}
        labelText="携帯電話番号"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderInformationEmail"
        placeholder="取引責任者のメールアドレスを入力してください"
        isRequired={true}
        labelText="メールアドレス"
        ml={12}
      />
    </>
  );
};

export default TransactionPersonForm;
