import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import moment from 'moment';

import { JText as Text } from '../common/JText';
import HeaderAvatar from './HeaderAvatar';

const HeaderDashboard = () => {
  [time, setTime] = useState(() => {
    return moment().format('YYYY.MM.DD  HH:mm');
  });
  useEffect(() => {
    const getTime = setInterval(function () {
      setTime(moment().format('YYYY.MM.DD  HH:mm'));
    }, 1000);
    return () => clearInterval(getTime);
  }, []);

  return (
    <View style={styles.headerAdmin}>
      <View style={styles.headerLeft}>
        <Text style={styles.textHeader}>DASHBOARD</Text>
      </View>
      <View style={styles.dFlex}>
        <View style={styles.summaryTime}>
          <Image style={styles.summaryTimeImg} source={require('@assets/icons/iconClock.svg')} />
          <Text style={styles.textHeaderTittle}>{time} </Text>
        </View>
        <HeaderAvatar />
      </View>
    </View>
  );
};

export default HeaderDashboard;

const styles = StyleSheet.create({
  headerAdmin: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  textHeader: {
    fontFamily: 'BebasNeue',
    color: '#838383',
    fontSize: '42px',
    textTransform: 'uppercase',
  },
  summaryTime: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 80,
  },
  dFlex: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textHeaderTittle: {
    color: '#939393',
    fontSize: 20,
  },
  summaryTimeImg: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
});
