import { StyleSheet } from 'react-native';
import { BTN_COLOR_GREY, COLOR_TEXT_DEFAULT, FONT_FAMILY_HEADER, TEXT_COLOR_GREY } from '@assets/style/styleDefault';

const btnAction = {
  fontWeight: 400,
  lineHeight: 1.5,
  cursor: 'pointer',
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'initial',
  height: '55px',
  borderRadius: 4,
};

const styles = StyleSheet.create({
  backgroundColor: {
    backgroundColor: 'transparent',
  },
  warperTextHeaderAdmin: {
    paddingLeft: 60,
    paddingTop: 30,
  },
  warperTextHeaderBottom: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  textHeaderAdmin: {
    fontFamily: 'BebasNeue',
    fontSize: '41px',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  loginImage: {
    position: 'absolute',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    zIndex: 9,
  },
  backgroundVideo: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    height: '100%',
    backgroundSize: 'cover',
  },
  backgroundImg: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  loginContent: {
    display: 'block',
    margin: 0,
    backgroundColor: 'transparent',
  },
  container: {
    display: 'block',
    width: '100%',
    backgroundColor: 'transparent',
    marginHorizontal: 'auto',
    paddingVertical: 10,
    maxWidth: '1320px',
  },
  row: {
    display: ['-webkit-box', '-ms-flexbox', 'flex'],
    msFlexWrap: 'wrap',
    flexWrap: 'wrap',
    marginTop: 0,
    marginRight: '20px',
    marginLeft: '20px',
  },
  form: {
    backgroundColor: 'transparent',
    display: 'flex',
    paddingTop: '3%',
    position: 'relative',
    paddingRight: '48px',
    paddingLeft: '48px',
    WebkitBoxFlex: '0',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    width: '50%',
    maxWidth: '100%',
    minWidth: '250px',
    marginLeft: '10%',
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    paddingRight: '63px',
  },
  logo: {
    width: 40,
    height: 40,
    textAlignVertical: 'middle',
  },
  textHeader: {
    fontFamily: FONT_FAMILY_HEADER,
    margin: '0',
    display: 'block',
    marginInlineStart: 0,
    marginInlineEnd: 0,
  },
  colorS: {
    color: '#f2f2f2',
    fontSize: '55px',
    fontWeight: 500,
    fontFamily: FONT_FAMILY_HEADER,
  },
  whiteColor: {
    color: '#fff',
    fontSize: '55px',
    fontWeight: 500,
    fontFamily: FONT_FAMILY_HEADER,
  },
  formBody: {
    paddingRight: '50px',
    paddingLeft: '30px',
    paddingTop: '20px',
    paddingBottom: '30px',
  },

  relative: {
    position: 'relative',
    marginTop: 10,
    marginBottom: 20,
  },
  input: {
    backgroundColor: 'rgba(64,64,64,0.65)',
    height: 50,
    borderRadius: 4,
    paddingLeft: '50px',
    color: '#f2f2f2',
    fontSize: 14,
  },
  iconPrefix: {
    position: 'absolute',
    height: 20,
    width: 20,
    top: 14,
    left: 20,
  },
  iconMessage: {
    height: 15,
    top: 18,
  },
  iconBlock: {
    height: 23,
    width: 19,
    top: 12,
  },
  buttonForcus: {
    border: '1px solid green',
    backgroundColor: 'red',
    color: 'red',
  },
  formLink: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
    fontSize: '14px',
    paddingLeft: '35px',
    paddingTop: '30px',
  },

  imgForget: {
    borderRadius: '50px',
    width: 30,
    height: 30,
  },
  textForget: {
    marginLeft: 10,
    color: COLOR_TEXT_DEFAULT,
    borderBottomWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#7d7f81',
    fontSize: '14px',
    marginBottom: '10px',
  },
  formAction: {
    marginTop: '66px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  buttonAction: {
    ...btnAction,
    backgroundColor: BTN_COLOR_GREY,
  },
  textBtnAction: {
    marginLeft: 10,
    color: TEXT_COLOR_GREY,
  },
  Inputtype: {
    backgroundColor: 'rgba(64,64,64,0.65)',
    height: 50,
    border: '1px solid transparent',
    minWidth: '250px',
  },
  buttonSubmit: {
    ...btnAction,
  },

  padding10: {
    paddingVertical: 20,
    minWidth: '240px',
  },
  signInLine: {
    width: 40,
    border: '1px solid white',
    opacity: 0.4,
    position: 'absolute',
    right: 0,
  },
  textInc: { color: '#B9B9B9' },
  textRed: { color: 'red' },
});

export default styles;
