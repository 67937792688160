import React from 'react';
import { View } from 'react-native';

import { JText as Text } from '@components/common/JText';
import styles from '../styles';

const OverviewTable = ({ data }) => {
  return (
    <>
      <View style={styles.OverviewTableBorderBottom}>
        <Text style={styles.OverviewTableContent}>未請求件数</Text>
        <Text style={styles.OverviewTableValue}> {formatNumber(data?.pendingRefundCount ?? '')}</Text>
      </View>
      <View style={styles.OverviewTableBorderBottom}>
        <Text style={styles.OverviewTableContent}>全請求件数</Text>
        <Text style={styles.OverviewTableValue}> {formatNumber(data?.refundedCount ?? '')}</Text>
      </View>
      <View style={styles.OverviewTableBorderBottom}>
        <Text style={styles.OverviewTableContent}>払戻請求件数</Text>
        <Text style={styles.OverviewTableValue}>{formatNumber(data?.requestCount ?? '')}</Text>
      </View>
    </>
  );
};

export default OverviewTable;
