import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { NavLink, useLocation } from 'react-router-dom';
import { Video } from 'expo-av';

import { JText as Text } from '@components/common/JText';
import styles from './style';

const Completed = () => {
  const location = useLocation();
  const checkLocation = location.pathname == '/forgot-password/phone/completed';
  const [isErrorBackgroundVideo, setErrorBackgroundVideo] = useState(false);
  return (
    <>
      <View style={styles.backgroundColor}>
        {isErrorBackgroundVideo ? (
          <Image style={styles.backgroundImg} source={require('@assets/img/login.jpg')} alt="background login" />
        ) : (
          <Video
            source={require('@assets/videos/login-background.mp4')}
            style={styles.backgroundVideo}
            posterStyle={styles.backgroundImg}
            rate={1}
            shouldPlay={true}
            isLooping={true}
            isMuted={true}
            posterSource={require('@assets/img/login.jpg')}
            usePoster={true}
            onError={() => setErrorBackgroundVideo(true)}
            VideoNaturalSize={{ width: 1200 }}
            resizeMode="cover"
          />
        )}
        <View style={styles.backGroup}>
          <View style={styles.form}>
            <View style={styles.formHeader}>
              <View style={[styles.textHeader, styles.flexDirection]}>
                <Image source={require('@assets/icons/successGreen.svg')} style={styles.iconClose} />
                <Text style={styles.colorS}>{checkLocation ? 'SMSを送信しました' : 'パスワードの再設定完了'}</Text>
              </View>
            </View>
            <View style={styles.formBody}>
              <View>
                <Text style={styles.textTitle}>
                  {checkLocation ? 'メールアドレスをSMS送信いたしました。' : 'パスワードの再設定が完了しました'}
                </Text>
              </View>
              <View>
                <Text style={styles.textTitle}>
                  {checkLocation ? 'SMSをご確認ください。' : '新しいパスワードでサインインしてください。'}
                </Text>
              </View>
            </View>
            <View style={styles.formAction}>
              <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
                <TouchableOpacity>
                  <NavLink to={'/login'} style={{ textDecorationLine: 'none' }}>
                    <View style={[styles.buttonSubmit]}>
                      <View style={styles.buttonSubmit}>
                        <Text style={styles.textBtnSubmit}>サインイン画面に戻る</Text>
                      </View>
                    </View>
                  </NavLink>
                </TouchableOpacity>
              </LinearGradient>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default Completed;
