import React from 'react';
import { Route, Switch, Redirect } from '../config/react-router-web';
import { getCookie } from '@utils/TokenStorage';

// Login page
import Login from '@pages/Login';
import ResetPassword from '@pages/Login/ResetPassword';
import Completed from '@pages/Login/Completed';
import ForgotPassword from '@pages/Login/ForgotEmail';
import ForgotAddress from '@pages/Login/ForgotPassword';

import Home from '@pages/Home';
import PaymentHistory from '@pages/PaymentHistory';
import RequestingRefund from '@pages/RequestingRefund';
import BillingHistory from '@pages/BillingHistory';

// AccountManagement list
import AccountShop from '@pages/AccountShop';
import BankInformation from '@pages/AccountShop/BankInformation';
// Others
import NotFoundPage from '@pages/404';
import { STORAGEKEY } from '@helper/constant';
import MerchantRefundAmount from '@pages/MerchantRefundAmount';

export const appRouter = [
  //login
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },

  {
    name: 'ForgotPasswordAddress ',
    path: '/forgot-password/address',
    component: ForgotAddress,
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'ForgotPasswordAddress Competed',
        path: '/completed',
        component: Completed,
        meta: {
          role: '*',
          isPrivate: false,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  {
    name: 'ForgotPasswordPhone ',
    path: '/forgot-password/phone',
    component: ForgotPassword,
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'ForgotPasswordPhoneCompleted ',
        path: '/completed',
        component: Completed,
        meta: {
          role: '*',
          isPrivate: false,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  {
    name: 'ResetPassword ',
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      role: '*',
      isPrivate: false,
      hidden: false,
      child: false,
    },
    children: [
      {
        name: 'ResetPasswordCompleted',
        path: '/completed',
        component: Completed,
        meta: {
          role: '*',
          isPrivate: false,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  // home
  {
    name: 'Home',
    path: '/',
    component: Home,
    meta: {
      role: ['admin'],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },

  // PaymentHistory List
  {
    name: 'Payment History',
    path: '/payment-history',
    component: PaymentHistory,
    meta: {
      role: ['admin'],
      isPrivate: true,
      hidden: false,
      child: true,
    },
  },

  //Requesting Refund
  {
    name: 'Requesting Refund',
    path: '/requesting-refund',
    component: RequestingRefund,
    meta: {
      role: ['admin'],
      isPrivate: true,
      hidden: false,
      child: true,
    },
  },
  //Billing History
  {
    name: 'Billing History',
    path: '/billing-history',
    component: BillingHistory,
    meta: {
      role: ['admin'],
      isPrivate: true,
      hidden: false,
      child: true,
    },
  },

  //Account shop
  {
    name: 'Account Shop',
    path: '/shop/details',
    component: AccountShop,
    meta: {
      role: ['admin'],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Merchant Refund Amount',
    path: '/merchant-refund-amount',
    component: MerchantRefundAmount,
    meta: {
      role: ['admin'],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'Bank Information',
    path: '/shop/details/bank',
    component: BankInformation,
    meta: {
      role: ['admin'],
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
];

export const whiteList = [
  '/login',
  '/reset-password',
  '/forgot-password/phone',
  '/forgot-password/phone/completed',
  '/forgot-password/address',
  '/forgot-password/address/completed',
  '/reset-password/completed',
];

const PrivateRoute = (props) => {
  return (
    <Route
      path={props.path}
      exact
      render={(prop) =>
        getCookie(STORAGEKEY.ACCESS_TOKEN) ? (
          <>{React.createElement(props.component, prop)}</>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { redirect_url: prop.location },
            }}
          />
        )
      }
    />
  );
};

const WhiteListRoute = (props) => {
  const isWhiteList = (path) => !getCookie(STORAGEKEY.ACCESS_TOKEN) && whiteList.indexOf(path) >= 0;

  return (
    <Route
      path={props.path}
      exact
      render={(prop) =>
        isWhiteList(props.path) ? (
          <>{React.createElement(props.component, prop)}</>
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
};

const renderRouter = (routes) => {
  let arr = [];
  routes.forEach((route) => {
    const tmpRoute = route.meta.isPrivate ? (
      <PrivateRoute exact path={route.path} component={route.component} key={route.name} />
    ) : (
      <WhiteListRoute exact path={route.path} component={route.component} key={route.name} />
    );

    arr.push(tmpRoute);

    if (route.children) {
      const newRoute = route.children.map((item) => {
        item.path = route.path.concat(item.path);
        return item;
      });
      arr = arr.concat(renderRouter(newRoute));
    }
  });

  return arr;
};

export const routes = () => {
  return (
    <Switch>
      {renderRouter(appRouter).map((render) => render)}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default routes;
