import React from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { JText as Text } from '../common/JText';

const HeaderUserId = () => {
  const userInfo = useSelector((state) => state.merchantUserInfo);
  return (
    <Text style={styles.textId}>
      LEVICA ID　 S{parseInt(userInfo?.id) < 9999 ? `0000${userInfo?.id}`.slice(-4) : userInfo?.id}
    </Text>
  );
};

export default HeaderUserId;

const styles = StyleSheet.create({
  textId: {
    textAlign: 'end',
    color: 'white',
    fontSize: 24,
    paddingRight: 20,
    paddingHorizontal: 20,
    fontSize: 24,
  },
});
