import { extendTheme } from 'native-base';
import { BG_CHECKBOX, TEXT_COLOR_GREEN } from './styleDefault';
export const theme = extendTheme({
  colors: {
    CHECKBOX_COLOR: TEXT_COLOR_GREEN,
    BG_CHECKBOX: BG_CHECKBOX,
  },
  components: {
    // custom config radio
    Radio: {
      baseStyle: {
        borderWidth: 0,
        p: '6px',
        _focusVisible: {
          _web: {
            style: {
              outlineWidth: '0px',
            },
          },
        },
      },
    },
    // custom config checkbox
    Checkbox: {
      baseStyle: {
        borderWidth: 0,
        borderRadius: 0,
        _focusVisible: {
          _web: {
            style: {
              outlineWidth: '0px',
            },
          },
        },
      },
      defaultProps: {
        size: 'lg',
      },
    },
  },
});
