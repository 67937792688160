import { StyleSheet } from 'react-native';
import { BG_COLOR, FONT_FAMILY_HEADER, FONT_SIZE_HEADER, FONT_WEIGHT_DEFAULT_HEADER } from '@assets/style/styleDefault';

//style common
const DFlex = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

//style home page
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: BG_COLOR, position: 'relative' },

  textHeaderTittle: {
    fontSize: '24px',
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
  },
  textHeaderTittleId: {
    fontSize: FONT_SIZE_HEADER,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
    color: 'white',
    textAlign: 'end',
  },
  homePage: {
    paddingHorizontal: 20,
  },

  //style card
  card: {
    ...DFlex,
    gap: 20,
    marginTop: 20,
  },

  CardImgBackground: {
    height: '12vw',
    width: 'auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'right',
  },
  CardContent: {
    paddingTop: '10%',
    paddingRight: '10%',
  },
  CardContentNumber: {
    // fontSize: '1.7em',
    fontSize: 29,
    color: '#fff',
    textAlign: 'right',
  },
  CardContentText: {
    // fontSize: '1.5em',
    fontSize: 23,
    color: '#fff',
    textAlign: 'right',
  },
  //style OverviewTable

  OverviewTable: {
    width: '45vw',
    minWidth: 600,
    maxWidth: 700,
    marginVertical: 30,
    marginBottom: 80,
  },
  OverviewTableBorderBottom: {
    ...DFlex,
    paddingVertical: 10,
    borderBottomColor: '#3f3e3e',
    borderBottomWidth: 1,
  },
  OverviewTableNoBorderBottom: {
    ...DFlex,
    paddingVertical: 15,
  },
  OverviewTableContent: {
    fontSize: 18,
    paddingLeft: 5,
  },
  OverviewTableValue: {
    fontSize: 23,
    paddingRight: 40,
  },
  //style contact
  textHeader: {
    fontFamily: FONT_FAMILY_HEADER,
    color: 'white',
    fontSize: 39,
    textTransform: 'uppercase',
    marginVertical: 10,
  },
  dFlex: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  summaryTimeImg: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  contact: {
    paddingBottom: 20,
  },
  fontSize15: {
    fontSize: 15,
  },
});

export default styles;
