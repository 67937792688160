import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LinearGradient } from 'expo-linear-gradient';
import { CSVLink } from 'react-csv';

import formatNumber from '@helper/formatNumber';
import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import { BG_TABLE_DEFAULT, INPUT_BG, LINE_COLOR, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import DateTimePicker from '@components/common/DateTimePicker';
import { downloadCSV, get } from '@utils/RequestLevica';
import HeaderTableList from '@components/Headers/HeaderTableList';
import { API_PATH } from '@helper/constant';
import styles from '../PaymentHistory/style';

const defaultValues = {
  startDate: '',
  endDate: '',
};
const validationSchema = Yup.object().shape({
  startDate: Yup.string(),
  endDate: Yup.string(),
});
const tableHeadData = [
  {
    title: '取引番号',
    size: 3,
  },
  {
    title: '決済日',
    hasIcon: true,
    sort: '0100',
    order: false,
    size: 3,
  },
  {
    title: 'ユーザーLEVICA ID',
    size: 3,
  },
  {
    title: '決済金額',
    hasIcon: true,
    sort: '0200',
    order: false,
    size: 3,
  },
  {
    title: '払い戻し金額',
    hasIcon: true,
    sort: '0200',
    order: false,
    size: 3,
  },
  {
    title: '再利用金額',
    hasIcon: true,
    sort: '0200',
    order: false,
    size: 3,
  },
  {
    title: '精算ステータス',
    hasIcon: true,
    sort: '0200',
    order: false,
    size: 3,
  },
  {
    title: '選択 ',
    size: 1,
  },
];

const csvHeader = [
  {
    label: '取引番号',
    key: 'transactionNumber',
  },
  {
    label: '決済日',
    key: 'paymentDate',
  },
  {
    label: 'ユーザーLEVICA ID',
    key: 'userId',
  },
  {
    label: '決済金額',
    key: 'amountPaid',
  },
];
const csvDataDefault = [
  {
    amountPaid: '',
    paymentDate: '',
    transactionNumber: '',
    userId: '',
  },
];

const PaymentHistory = () => {
  const [pageExport, setPageExport] = useState([]);
  const [itemCheck, setItemCheck] = useState([]);
  const [exportAll, setExportAll] = useState(false);
  const [dataCSVExport, setDataCSVExport] = useState(null);

  const [paymentHistoryTable, setPaymentHistoryTable] = useState([]);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [paymentHistoryDataGet, setPaymentHistoryDataGet] = useState(null);
  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    startDate: '',
    endDate: '',
    sort: '0100',
    order: 'desc',
  });

  const selectAll = async () => {
    const listChecked = paymentHistoryTable?.filter((item) => {
      return item.pickup === true;
    });
    const newListData = paymentHistoryTable?.map((item) => ({
      ...item,
      pickup: listChecked?.length > 0 ? (listChecked?.length === paymentHistoryTable?.length ? false : true) : true,
    }));
    setPaymentHistoryTable(newListData);
    setExportAll(!exportAll);
  };

  const toggleCheckbox = (item, index) => {
    if (exportAll) {
      setExportAll(false);
    }
    const newListData = [...paymentHistoryTable];
    newListData?.splice(index, 1, { ...item, pickup: !item.pickup });
    setPaymentHistoryTable(newListData);
    if (pageExport.indexOf(params.pageNumber) < 0) {
      setPageExport([...pageExport, params.pageNumber]);
    }
  };

  const saveItemCheck = () => {
    const listCheckedTrue = paymentHistoryTable?.filter((item) => {
      return item.pickup === true;
    });
    const idOfListCheckedTrue = listCheckedTrue.map((item) => item.transactionNumber);
    const listCheckedFalse = paymentHistoryTable?.filter((item) => {
      return item.pickup === false;
    });
    const idOfListCheckedFalse = listCheckedFalse.map((item) => item.transactionNumber);
    newItemCheck = itemCheck.filter((itemRemove) => !idOfListCheckedFalse.includes(itemRemove));
    setItemCheck([...new Set([...newItemCheck, ...idOfListCheckedTrue])]);
  };

  const handleExportCSV = async () => {
    if (exportAll) {
      await downloadCSV(API_PATH.PAYMENT_HISTORY_EXPORT_CSV, '決済履歴', params);
    } else {
      const listChecked = paymentHistoryTable?.filter((item) => {
        return item.pickup === true;
      });
      setDataCSVExport(listChecked);
    }
  };

  useEffect(() => {
    const getTransactionHistory = async () => {
      const data = await get(API_PATH.PAYMENT_HISTORY, params);
      setPaymentHistoryDataGet(data);
      if (pageExport.includes(params.pageNumber)) {
        const newData = data?.payHistoryList?.map((item) => ({
          ...item,
          amountPaid: item.amountPaid ? `¥ ${formatNumber(item.amountPaid)}` : '',
          pickup: itemCheck?.includes(item?.transactionNumber) ? true : false,
        }));
        setPaymentHistoryTable(newData);
      } else {
        const newData = data?.payHistoryList?.map((item) => ({
          ...item,
          amountPaid: item.amountPaid ? `¥ ${formatNumber(item.amountPaid)}` : '',
          pickup: exportAll ? true : false,
        }));
        setPaymentHistoryTable(newData);
      }
    };
    getTransactionHistory();
  }, [params]);

  const handleSort = (item) => {
    tableHeadData.forEach((i) => {
      if (item.sort === i.sort) {
        i.order = !i.order;
      } else {
        i.order = false;
      }
    });
    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: item.order ? 'asc' : 'desc',
    });
  };

  const { control, handleSubmit } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    const newParams = {
      ...params,
      startDate: moment(data.startDate).format('YYYY-MM-DD'),
      endDate: moment(data.endDate).format('YYYY-MM-DD'),
      pageNumber: 1,
    };
    setParams(newParams);
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.wrapperStickyHeader}>
          <HeaderTableList
            textHeader="決済履歴"
            textHeaderTitle="アプリ利用者からあなたのお店で決済された履歴です。"
            textInfoTitle="決済履歴件数"
            textInfoValue="累計"
            data={paymentHistoryDataGet}
          />
          <Grid style={styles.wrapperSearch}>
            <Col size={7} style={[styles.leftSearch, { minWidth: 800 }]}>
              <View style={styles.wrapperInput}>
                <View style={styles.wrapperDateTimePicker}>
                  <DateTimePicker
                    name="startDate"
                    control={control}
                    type="date"
                    setDateTime={setStartDate}
                    max={endDate ? endDate : moment().format('YYYY-MM-DD')}
                    height={40}
                  />
                </View>
                <View>
                  <Text style={styles.textDateTimePicker}>~</Text>
                </View>
                <View style={styles.wrapperDateTimePicker}>
                  <DateTimePicker
                    height={40}
                    name="endDate"
                    control={control}
                    type="date"
                    min={startDate}
                    max={moment().format('YYYY-MM-DD')}
                    setDateTime={setEndDate}
                  />
                </View>
                <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5, width: 150 }}>
                  <JButton
                    text="期間指定"
                    textColor="#fff"
                    linkIcon="iconClockActive.svg"
                    heightIcon={21}
                    widthIcon={21}
                    disabled={!(startDate && endDate)}
                    linkIconDisabled="iconClock.svg"
                    onPressAction={() => handleSubmit(onSubmit)()}
                  />
                </LinearGradient>
              </View>

              <JButton
                text="一括チェック"
                onPressAction={() => selectAll()}
                width={150}
                textColor={TEXT_COLOR_GREEN}
                btnColor={INPUT_BG}
                borderBtn={`1px solid ${LINE_COLOR}`}
              />
              {exportAll ? (
                <Text style={[styles.CsvActive, { textDecoration: 'underline' }]} onPress={handleExportCSV}>
                  CSVファイル出力
                </Text>
              ) : (
                <CSVLink
                  data={dataCSVExport ?? csvDataDefault}
                  headers={csvHeader}
                  target="_blank"
                  filename={'決済履歴'}
                  style={{ textDecorationColor: TEXT_COLOR_GREEN }}
                >
                  <Text style={styles.CsvActive} onPress={handleExportCSV}>
                    CSVファイル出力
                  </Text>
                </CSVLink>
              )}
            </Col>
          </Grid>

          <Grid>
            <Col size={100} style={{ minWidth: 800 }}>
              <Grid style={styles.head} size={30}>
                {tableHeadData.map((item, index, { length }) => (
                  <Col
                    size={item.size}
                    style={[
                      styles.alignItemsCenter,
                      {
                        paddingRight: index + 1 === length ? 40 : '',
                        visibility: paymentHistoryTable?.length > 0 ? 'visible' : 'hidden',
                      },
                    ]}
                    key={item.title}
                  >
                    <Text numberOfLines={2} style={styles.textHead}>
                      {item.title}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => handleSort(item)}
                        style={item.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`@assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            </Col>
            <Col size={3} />
          </Grid>
        </View>
        <Grid>
          {paymentHistoryTable?.length === 0 ? (
            <Col size={100} style={styles.tableNoData}>
              <Text style={styles.text}>{'決済履歴がありません。'}</Text>
            </Col>
          ) : (
            <Col size={100} style={{ backgroundColor: BG_TABLE_DEFAULT, minWidth: 800 }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                {paymentHistoryTable?.map((item, index, { length }) => {
                  return (
                    <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                      <Grid style={[styles.headBody, { borderBottomWidth: index + 1 === length ? 0 : 1 }]} size={30}>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.transactionNumber}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text numberOfLines={2} style={styles.text}>
                            {item?.paymentDate}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text} numberOfLines={3}>
                            {item.userId}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.amountPaid}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.refundAmountFormat ?? '¥ 0'}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.levicaTransferAmountFormat ?? '¥ 0'}
                          </Text>
                        </Col>
                        <Col size={3} style={styles.alignItemsCenter}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.status}
                          </Text>
                        </Col>
                        <Col size={1} style={[styles.alignItemsCenter, { paddingRight: 40 }]}>
                          <JCheckbox
                            checkValueTable={true}
                            onValueChange={() => toggleCheckbox(item, index)}
                            value={item?.pickup ?? false}
                          />
                        </Col>
                      </Grid>
                    </Pressable>
                  );
                })}
                <View>
                  <Pagination
                    count={Math.ceil(paymentHistoryDataGet?.pageInfo?.totalCount / params.limit)}
                    params={params}
                    setParams={setParams}
                    saveItemCheck={saveItemCheck}
                  />
                </View>
              </ScrollView>
            </Col>
          )}
          <Col size={3} />
        </Grid>
      </View>
    </>
  );
};

export default PaymentHistory;
