import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { COLOR_TEXT_DEFAULT, FONT_FAMILY_DEFAULT, FONT_SIZE_DEFAULT } from '@assets/style/styleDefault';

export const JText = ({ style, children, ...props }) => {
  return (
    <Text style={[styles.defaultText, style]} {...props}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  defaultText: {
    fontFamily: FONT_FAMILY_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
    color: COLOR_TEXT_DEFAULT,
  },
});
