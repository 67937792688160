import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView } from 'react-native';
import { Box } from 'native-base';

import HeaderDashboard from '@components/Headers/HeaderDashboard';
import Card from './partials/Card';
import OverviewTable from './partials/OverviewTable';
import { JText as Text } from '@components/common/JText';
import { TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import HeaderUserId from '@components/Headers/HeaderUserId';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import styles from './styles';

const Home = () => {
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    let mounted = true;
    const getDashboard = async () => {
      const data = await get(API_PATH.DASHBOARD);
      if (mounted) {
        setDashboardData(data);
      }
    };
    getDashboard();
    return () => (mounted = false);
  }, []);

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.homePage}>
          <HeaderDashboard />
          <HeaderUserId />
          <Text style={styles.textHeaderTittle}>サマリー </Text>
          <View style={styles.card}>
            <Card data={dashboardData} />
            <Box w={{ xl: '15vw' }} />
          </View>

          <View style={styles.OverviewTable}>
            <OverviewTable data={dashboardData} />
          </View>

          <View style={styles.contact}>
            <Text style={styles.textHeaderTittle}>お問合せ窓口</Text>
            <Text style={[styles.textHeader, { color: TEXT_COLOR_GREEN }]}>
              L<Text style={styles.textHeader}>EVICA INC</Text>
            </Text>
            <View style={styles.dFlex}>
              <Image style={styles.summaryTimeImg} source={require('@assets/icons/mailOval.svg')} />
              <Text style={styles.OverviewTableContent}>info@levias.co.jp</Text>
            </View>
            <View style={styles.dFlex}>
              <Image style={styles.summaryTimeImg} source={require('@assets/icons/phoneOVal.svg')} />
              <Text style={styles.OverviewTableContent}>03-6402-3330</Text>
            </View>
            <Text style={styles.fontSize15}>
              月曜日から金曜日の午前8時30分から午後5時15分 ※祝日年末年始(12月29日〜1月3日)を除く
            </Text>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default Home;
