import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import { ScrollView } from 'react-native-web';
import { Col, Grid } from 'react-native-easy-grid';
import moment from 'moment';

import { get } from '@utils/RequestLevica';
import FromIndividual from './FromIndividual';
import { JText as Text } from '@components/common/JText';
import WalletInfo from './FormBankInfo/WalletInfo';
import BankInfo from './FormBankInfo/BankInfo';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import HeaderUserId from '@components/Headers/HeaderUserId';
import { BG_COLOR } from '@assets/style/styleDefault';
import { API_PATH } from '@helper/constant';
import formatNumber from '@helper/formatNumber';

const corporationTypeValue = [
  '株式会社',
  '有限会社',
  '合名会社',
  '合資会社',
  '合同会社',
  '医療法人',
  '医療法人社団',
  '医療法人財団',
  '社会医療法人',
  '財団法人',
  '一般財団法人',
  '公益財団法人',
  '社団法人',
  '一般社団法人',
  '公益社団法人',
  '宗教法人',
  '学校法人',
  '社会福祉法人',
  '更生保護法人',
  '相互会社',
  '特定非営利活動法人',
  '独立行政法人',
  '地方独立行政法人',
  '弁護士法人',
  '有限責任中間法人',
  '無限責任中間法人',
  '行政書士法人',
  '司法書士法人',
  '税理士法人',
  '国立大学法人',
  '公立大学法人',
  '農事組合法人',
  '管理組合法人',
  '社会保険労務士法人',
];
const industryValue = [
  '金融業',
  '情報通信業',
  '農業・林業',
  '漁業',
  '鉱業',
  '採石業',
  '砂利採取業',
  '建設業',
  '製造業',
  '電気・ガス・熱供給・水道業',
  '運輸業',
  '郵便業',
  '卸売業・小売業',
  '不動産業',
  '物品賃貸業',
  '学術研究',
  '専門・技術サービス業',
  '宿泊業',
  '飲食サービス業',
  '生活関連サービス業',
  '娯楽業',
  '教育・学習支援業',
  '医療・福祉',
  '複合サービス事業',
  'サービス業（他に分類されないもの）',
];

const AccountShopDetail = () => {
  const [shopInfo, setShopInfo] = useState();
  const methods = useForm();

  useEffect(() => {
    const getUser = async () => {
      try {
        const data = await get(API_PATH.SHOP_INFO);
        const newFormValueForTest = {
          ...data?.merchant,
          capital: formatNumber(data?.merchant.capital),
          industry: industryValue[Number(data?.merchant?.industry) - 1],
          corporationType: corporationTypeValue[Number(data?.merchant?.corporationType) - 1],
          merchantUserInformationLastName: data?.merchantUser?.lastName,
          merchantUserInformationLastNameKana: data?.merchantUser?.lastNameKana,
          merchantUserInformationFirstName: data?.merchantUser?.firstName,
          merchantUserInformationFirstNameKana: data?.merchantUser?.firstNameKana,
          merchantUserInformationTitle: data?.merchantUser?.title,
          merchantUserInformationEmail: data?.merchantUser?.email,
          traderInformationDepartment: data?.trader?.department,
          traderInformationDirector: data?.trader?.director,
          traderInformationLastName: data?.trader?.lastName,
          traderInformationLastNameKana: data?.trader?.lastNameKana,
          traderInformationFirstName: data?.trader?.firstName,
          traderInformationFirstNameKana: data?.trader?.firstNameKana,
          traderInformationPhoneNumber: data?.trader?.phoneNumber,
          traderInformationEmail: data?.trader?.email,
          traderInformationPostCode: data?.trader?.postCode,
          traderInformationPrefecture: data?.trader?.prefecture,
          traderInformationCity: data?.trader?.city,
          traderInformationAddress: data?.trader?.address,
          traderInformationBuilding: data?.trader?.building,
          traderInformationFloor: data?.trader?.floor,
          traderInformationRoom: data?.trader?.room,
          paymentQr: data?.paymentQr,
          walletQr: data?.walletQr,
          establishedAt: data?.merchant?.establishedAt
            ? moment(data?.merchant?.establishedAt).format('YYYY 年 MM 月 DD 日')
            : '',
          tokens: data?.tokens,
        };
        setShopInfo(newFormValueForTest);
        methods.reset(newFormValueForTest);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);

  return (
    <FormProvider {...methods}>
      <View>
        <View style={styles.avatar}>
          <HeaderAvatar />
        </View>
        <HeaderUserId />
        <View style={styles.dFlex}>
          <Image style={styles.iconBuilding} source={require(`@assets/icons/building.svg`)} />
          <Text style={styles.textTitle}>加盟店アカウント詳細情報</Text>
        </View>
      </View>

      <View>
        <Grid style={styles.container}>
          <Col size={7}>
            <View style={styles.warperItem}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <FromIndividual formValue={shopInfo} control={methods.control} />
              </ScrollView>
            </View>
          </Col>
          <Col size={5}>
            <View style={styles.warperItem}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.containerRight}>
                  <BankInfo formValue={shopInfo} />
                </View>
                <WalletInfo wallets={shopInfo?.tokens} />
              </ScrollView>
            </View>
          </Col>
        </Grid>
      </View>
    </FormProvider>
  );
};

export default AccountShopDetail;

const styles = StyleSheet.create({
  container: {
    gap: 50,
    backgroundColor: BG_COLOR,
    paddingHorizontal: 20,
  },
  dFlex: { flexDirection: 'row', alignItems: 'center', marginBottom: 30 },
  avatar: { alignItems: 'flex-end', paddingRight: 20, marginVertical: 15 },
  iconBuilding: { width: 27, height: 31, marginHorizontal: 20 },
  warperItem: { height: 'calc(100vh - 180px)' },

  textId: {
    fontSize: 16,
    textAlign: 'end',
    paddingRight: 20,
  },
  textTitle: {
    fontSize: 25,
  },
});
