// Validated custom
export const TXT_REQUIRED = 'この項目は必須です。';
export const TXT_NUMERIC = '数字を入力してください。';
export const TXT_EMAIL_REQUIRED = '有効なメールアドレスを入力してください。';
export const TXT_LOGIN_FAIL = 'メールアドレスまたはパスワードが正しくありません。';
export const TXT_CAPTCHA_REQUIRED = '認証コードを入力してください。';
export const TXT_CAPTCHA_NOT_MATCH = '認証コードが正しくありません。';
//password
export const TXT_NEW_PASSWORD = 'パスワードを入力してください。'; // input  a new pass
export const TXT_MAX_32 = '32文字以内で入力してください。'; //new pass <  32 characters
export const TXT_VALIDATE_NEW_PASSWORD = ' パスワードは、8文字以上の英数字で英文字の大文字と小文字 を含めてください。'; //validate pass
export const TXT_CONFIRM_PASSWORD = 'パスワード確認とパスワードの入力が一致しません。'; //Enter the confirmation password that does not match the password
export const TXT_INPUT_CONFIRM_PASSWORD = 'パスワード確認を入力してください。';
//validate common
export const TXT_MAX_1000 = '1000文字以内で入力してください。'; //new pass <  1000 characters
export const TXT_MAX_100 = '100文字以内で入力してください。';
export const TXT_MAX_50 = '50文字以内で入力してください。';
export const TXT_MAX_20 = '20文字以内で入力してください。';
export const TXT_MAX_10_11 = '10～11数字を入力してください。';
export const TXT_VALIDATE_3_5 = '少なくとも数字と文字列と大文字、3 ～ 5 文字'; //3 to 5 characters , have number string uppercase
export const TXT_REGISTERED_EMAIL = 'メールアドレスは既に存在しています。'; // The Email was registered
export const TXT_EMAIL_NOT_REGISTERED = 'このメールアドレスは登録されていません。'; // The Email was registered
export const OTHER_ERROR = '申し訳ありませんが、何かエラーがあります'; // sorry, there is something error
export const WRONG_PHONE_NUMBER = 'あなたは間違った電話番号を入力しました'; //you entered the wrong phone number
export const JP_PHONE = '有効な電話番号を入力して下さい';
