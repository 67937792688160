import React, { useEffect } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import AccountInput from '@components/AccountComponents/AccountInput';
import styles from './styles';
import { JText as Text } from '@components/common/JText';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import HeaderUserId from '@components/Headers/HeaderUserId';
import { API_PATH } from '@helper/constant';
import { get } from '@utils/RequestLevica';

const accountTypeValue = ['普通', '当座'];

const BankInfomation = () => {
  const methods = useForm();
  const control = methods.control;
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      try {
        const data = await get(API_PATH.SHOP_INFO);
        const newData = {
          ...data?.merchantBankInfo,
          accountType: accountTypeValue[data?.merchantBankInfo?.accountType - 1],
        };
        methods.reset(newData);
      } catch (error) {
        console.error(error);
      }
    };
    getUser();
  }, []);

  return (
    <View>
      <View style={styles.wrapperHeader}>
        <View style={styles.dFlex}>
          <TouchableOpacity onPress={() => history.goBack()}>
            <View style={styles.dFlex}>
              <Image style={styles.iconLeft} source={require(`@assets/icons/iconLeft.svg`)} />
              <Text style={styles.textBack}>戻る</Text>
            </View>
          </TouchableOpacity>
          <View style={styles.dFlex}>
            <Image style={styles.iconBank} source={require(`@assets/icons/bank-card.svg`)} />
            <Text style={styles.textHeader}>銀行口座情報</Text>
          </View>
        </View>

        <HeaderAvatar />
      </View>
      <View style={styles.pl20}>
        <HeaderUserId />
        <View style={styles.wrapperContent}>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={false}
                control={control}
                name="bankCode"
                placeholder="金融機関コードを入力してください"
                isRequired={true}
                labelText="金融機関コード "
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={false}
                control={control}
                name="bankName"
                placeholder="金融機関名を入力してください"
                isRequired={true}
                labelText="金融機関名 "
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={false}
                control={control}
                name="branchCode"
                placeholder=" 支店コードを入力してください"
                isRequired={true}
                labelText="支店コード"
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={false}
                control={control}
                name="branchName"
                placeholder=" 支店名を入力してください"
                isRequired={true}
                labelText="支店名"
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={false}
                control={control}
                placeholder=" 口座種類を選択してください"
                isRequired={true}
                labelText="口座種類"
                name="accountType"
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={false}
                control={control}
                name="accountNumber"
                placeholder=" 口座番号を入力してください"
                isRequired={true}
                labelText="口座番号"
              />
            </Col>
            <Col size={3} />
          </Grid>

          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={false}
                control={control}
                name="accountHolderName"
                placeholder="名義人を半角カタカナで入力してください"
                isRequired={true}
                labelText="名義人"
              />
            </Col>
            <Col size={3} />
          </Grid>
        </View>
      </View>
    </View>
  );
};

export default BankInfomation;
