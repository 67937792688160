import React from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import styles from './style';
import { JText as Text } from '@components/common/JText';

const NotFoundPage = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.textContent}>ページが見つかりません</Text>
      <Link to={'/'}>
        <Text style={styles.textLink}>ホームページに戻る</Text>
      </Link>
    </View>
  );
};

export default NotFoundPage;
