import axios from 'axios';
import { HTTP_STATUS, STORAGEKEY } from '@helper/constant';
import { getCookie, logout } from './TokenStorage';

const baseURL = process.env.API_URL_LEVICA?.endsWith('/')
  ? process.env.API_URL_LEVICA
  : process.env.API_URL_LEVICA + '/';

const instanceSecondary = axios.create({
  baseURL: baseURL,
});

const setHeader = async () => {
  const token = (await getCookie(STORAGEKEY.ACCESS_TOKEN)) ?? '';
  instanceSecondary.defaults.headers.common['Authorization'] = `${token}`;
};

export const get = async (path, params = {}) => {
  try {
    await setHeader();
    const config = { params };
    const response = await instanceSecondary.get(path, config);
    return _responseHandler(response);
  } catch (error) {
    return _errorHandler(error);
  }
};

export const put = async (path, data = {}) => {
  try {
    let response = {};
    if (data.toLocaleString() === '[object FormData]') {
      response = await instanceSecondary.put(path, data);
    } else {
      response = await instanceSecondary.put(path, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
    return _responseHandler(response);
  } catch (error) {
    _errorHandler(error);
  }
};

export const post = async (path, data = {}) => {
  try {
    await setHeader();
    const response = await instanceSecondary.post(path, data);
    return _responseHandler(response);
  } catch (error) {
    return _errorHandler(error);
  }
};

export const del = async (path, data = {}) => {
  try {
    await setHeader();
    const response = await instanceSecondary.delete(path, data);
    return _responseHandler(response);
  } catch (error) {
    return _errorHandler(error);
  }
};

export const downloadCSV = async (path, csvName, params) => {
  const token = (await getCookie(STORAGEKEY.ACCESS_TOKEN)) ?? '';
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);
  let url = `${baseURL}${path}?startDate=${params.startDate}&endDate=${params.endDate}&sort=${params.sort}&order=${params.order}`;
  let headers = new Headers();
  headers.append('Authorization', token);
  try {
    fetch(url, { headers })
      .then((response) => response.blob())
      .then((fileType) => {
        let objectUrl = window.URL.createObjectURL(fileType);

        anchor.href = objectUrl;
        anchor.download = `${csvName}.csv`;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  } catch (error) {
    console.error(error.massage);
    return _errorHandler(error);
  }
};

const _responseHandler = (response, url) => {
  return response.data;
};

const _errorHandler = async (error) => {
  if (error.response && error.response.status === HTTP_STATUS.UNAUTHORIZED) {
    logout();
  }
  throw error;
};
