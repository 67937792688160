import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useLocation } from 'react-router-dom';

import Sidebar from '@components/SiderbarTabs/Sidebar';
import Routes from '@config/routers';
import { BG_COLOR } from '@assets/style/styleDefault';
import { whiteList } from '@config/routers';

const MainLayout = () => {
  const location = useLocation();
  const [routes, setRoutes] = useState(null);

  useEffect(() => {
    setRoutes(Routes());
  }, []);

  return (
    <View style={styles.main}>
      {whiteList.indexOf(location.pathname) < 0 && (
        <View style={styles.sidebar}>
          <Sidebar />
        </View>
      )}
      <View style={styles.mainContent}>{routes}</View>
    </View>
  );
};

export default MainLayout;

const styles = StyleSheet.create({
  main: {
    flex: 1,
    flexDirection: 'row',
    minWidth: '1280px',
    flexBasis: '100vh',
  },
  sidebar: {
    backgroundColor: BG_COLOR,
  },
  mainContent: {
    flex: 5,
    width: '100%',
    backgroundColor: BG_COLOR,
    paddingLeft: 15,
    paddingRight: 15,
  },
});
