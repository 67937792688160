import React from 'react';
import { Image, Pressable, ScrollView, StyleSheet, View } from 'react-native';

import { FONT_FAMILY_HEADER, TEXT_COLOR_GREEN, BG_HOVER, LINE_COLOR } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import formatNumber from '@helper/formatNumber';
import { Center } from 'native-base';

const WalletInfo = ({ wallets }) => {
  return (
    <View style={styles.container}>
      <View style={styles.walletHeader}>
        <View style={styles.flex}>
          <Image source={require('@assets/icons/iconWallet.svg')} style={styles.image} />
          <Text style={styles.textHeading}>WALLET</Text>
        </View>
      </View>

      <View style={styles.warperBody}>
        {wallets?.length === 0 ? (
          <Center pt={20}>
            <Text style={styles.textGrey}>このユーザーが保有しているウォレットはありません。</Text>
          </Center>
        ) : (
          <ScrollView showsVerticalScrollIndicator={false}>
            {wallets?.map((item, index) => (
              <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]} key={index}>
                <View style={styles.walletItem} key={index}>
                  <View style={[styles.flex, { gap: 10 }]}>
                    {item?.icon ? (
                      <Image style={styles.walletIcon} source={{ uri: item.icon }} />
                    ) : (
                      <Image source={require('@assets/icons/iconWorld.svg')} style={styles.walletIcon} />
                    )}

                    <Text>{item.name}</Text>
                  </View>
                  <View style={[styles.flex, { gap: 10, paddingLeft: 10 }]}>
                    <Text>{formatNumber(item.balance)}</Text>
                    <Text>{item.nameSymbol}</Text>
                  </View>
                </View>
              </Pressable>
            ))}
            {/* <Center pt={20}>{wallets?.length > 6 ? <Text style={styles.textLinkFooter}>全て表示</Text> : null}</Center> */}
          </ScrollView>
        )}
      </View>
    </View>
  );
};

export default WalletInfo;

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    marginBottom: 50,
    borderRadius: 8,
    border: `1px solid ${LINE_COLOR}`,
  },
  image: {
    width: 36,
    height: 29,
    marginBottom: 3,
  },
  walletHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 30,
    padding: 30,
    paddingVertical: 10,
    paddingTop: 30,
  },
  warperBody: {
    height: 'calc(100vh - 590px)',
    minHeight: '200px',
    marginTop: 10,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textLink: {
    textDecorationStyle: 'none',
    color: TEXT_COLOR_GREEN,
    borderBottomColor: TEXT_COLOR_GREEN,
    borderBottomWidth: 1,
    marginLeft: 20,
  },
  textHeading: {
    fontSize: 28,
    marginLeft: 10,
    fontWeight: 500,
    fontFamily: FONT_FAMILY_HEADER,
  },
  listWallet: {
    height: 450,
    marginTop: 10,
  },
  textLinkFooter: {
    color: TEXT_COLOR_GREEN,
    borderBottomColor: TEXT_COLOR_GREEN,
    borderBottomWidth: 1,
    textAlign: 'center',
    width: 'fit-content',
  },

  walletIcon: {
    width: 30,
    height: 30,
    borderRadius: 100,
  },
  walletItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    paddingHorizontal: 30,
  },
  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
});
