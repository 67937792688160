import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_HOVER,
  BG_TABLE_DEFAULT,
  FONT_SIZE_HEADER,
  LINE_COLOR,
  TEXT_COLOR_GREEN,
  TEXT_COLOR_HEADER_TABLE,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: BG_COLOR,
    position: 'relative',
  },
  wrapperStickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 100,
  },
  head: { gap: 20, paddingTop: 60, paddingBottom: 30 },
  headBody: {
    height: 42,
    paddingVertical: 15,
    gap: 20,
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
  },
  textHeader: {
    color: '#838383',
    fontSize: 36,
  },
  textHeaderTitle: {
    color: '#A5A5A5',
    paddingLeft: 30,
    fontSize: 18,
  },
  textId: {
    textAlign: 'end',
    color: 'white',
    fontSize: FONT_SIZE_HEADER,
    paddingRight: 20,
    paddingHorizontal: 20,
    fontSize: 24,
  },

  textHead: {
    color: TEXT_COLOR_HEADER_TABLE,
    textAlign: 'center',
    fontSize: 15,
  },

  text: {
    fontSize: 17,
  },
  wrapperSearch: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  leftSearch: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperInput: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingRight: 60,
    gap: 20,
  },
  rightSearch: {
    flexDirection: 'row',
    paddingBottom: 4,
  },
  iconTableHeadASC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
  },
  iconTableHeadDESC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
    transform: [{ rotate: '180deg' }],
  },
  rowHovered: {
    backgroundColor: BG_HOVER,
  },
  alignItemsCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'hide',
  },

  CsvActive: {
    color: TEXT_COLOR_GREEN,
    fontSize: 17,
    paddingLeft: 20,
  },
  wrapperDateTimePicker: {
    minWidth: 180,
    border: `1px solid ${LINE_COLOR}`,
    borderRadius: 4,
  },
  textDateTimePicker: { paddingHorizontal: 5, fontWeight: '500', fontSize: 20 },
  table: { backgroundColor: BG_TABLE_DEFAULT, minWidth: 800 },
  tableNoData: {
    backgroundColor: BG_COLOR,
    minWidth: 800,
    height: 'auto',
    paddingLeft: 20,
  },

  inputLabel: {
    fontSize: 17,
    backgroundColor: BG_COLOR,
    minWidth: 800,
    height: 'auto',
    paddingLeft: 20,
    paddingBottom: 20
  },
  modal: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    margin: 0,
  },
  warper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  modalHeader: {
    paddingVertical: 25,
    paddingHorizontal: 150,
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
  },
  modalBody: {
    alignItems: 'center',
    paddingVertical: 25,
    paddingHorizontal: 25,
  },
  iconLogout: {
    width: 20.08,
    height: 16.11,
    marginRight: 30,
  },
  text: {
    color: '#191919',
    fontSize: 19,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  h55: {
    height: 55
  },
  rowWarpper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 150,
    paddingVertical: 25,
    backgroundColor: 'white',
    height: 100,
  }
});

export default styles;
