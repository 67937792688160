import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable } from 'react-native';
import { Col, Grid, Row } from 'react-native-easy-grid';
import { useForm, useFormContext, setError } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LinearGradient } from 'expo-linear-gradient';

import formatNumber from '@helper/formatNumber';
import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import { BG_ACTIVE_SIDEBAR, BTN_BG_COLOR_DISABLED, BTN_TXT_COLOR_DISABLED, TEXT_COLOR_GREEN, } from '@assets/style/styleDefault';
import DateTimePicker from '@components/common/DateTimePicker';
import { API_PATH } from '@helper/constant';
import styles from './style';
import HeaderMerchantRefundAmount from '@components/Headers/HeaderMerchantRefundAmount';
import Switch from "react-switch";
import AccountInput from '@components/AccountComponents/AccountInput';
import JInput from '@components/common/JInput';
import JNumber from '@components/common/JNumber';
import { get, post } from '@utils/RequestLevica';
import Modal from 'react-native-modal';
import ModalBackDrop from '@components/Modals/ModalBackDrop';
import { TouchableOpacity } from 'react-native-web';

const defaultValues = {
    startDate: '',
    endDate: '',
    transferAmount: 0,
    transferToLevica: false,
    leviasChainAddress: null
};
const validationSchema = Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
});

const MerchantRefundAmount = () => {
    const [setting, setSetting] = useState(null);
    const [shouldSettingSync, setShouldSettingSync] = useState(false)
    const [params, setParams] = useState({
        limit: 30,
        pageNumber: 1,
        startDate: '',
        endDate: ''
    });

    const [shouldShowAmountConfirmModal, setShouldShowAmountConfirmModal] = useState(false);
    const [shouldShowAmountSavedModal, setShouldShowAmountSavedModal] = useState(false);

    const [shouldShowAddressConfirmModal, setShouldShowAddressConfirmModal] = useState(false);
    const [shouldShowAddressSavedModal, setShouldShowAddressSavedModal] = useState(false);

    const [leviasChainAddressError, setLeviasChainAddressError] = useState(null);

    const getSetting = async () => {
        const data = await get(API_PATH.SETTING);
        setSetting(data);
        setValue("refundAmount", data.history.requestedAmount)
        setValue("leviasChainAddress", data.leviasChainAddress)
        setShouldSettingSync(true);
    };

    useEffect(() => {
        getSetting();
    }, [params]);

    const { control, handleSubmit, getValues, setValue, getFieldState, formState, reset, setError } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const saveSetting = async (data, callback, errorCallBack = () => {}) => {
        try {
            await post(API_PATH.SETTING, data);
            callback();
            reset({}, { keepValues: true })
        } catch (error) {
            errorCallBack(error)
        }
    }

    const saveAmount = () => {
        saveSetting({ ...setting, refundAmount: getValues("refundAmount")}, () => {
            setShouldShowAmountConfirmModal(false)
            setShouldShowAmountSavedModal(true)
            setShouldSettingSync(false); 
            getSetting()
        });
    }

    const saveLeviasChainAddress = () => {
        setError('leviasChainAddress', { type: 'custom', message: null});
        saveSetting({ ...setting, leviasChainAddress: getValues("leviasChainAddress")}, () => { 
            setShouldShowAddressConfirmModal(false)
            setShouldShowAddressSavedModal(true)
            setShouldSettingSync(false); 
            getSetting()
        }, (error) => {
            setError('leviasChainAddress', { type: 'custom', message: error.response?.data?.message });
            setShouldShowAddressConfirmModal(false)
        })
    }

    useEffect(() => {
        if(!shouldSettingSync) {
            return
        }
        saveSetting({...setting})
    }, [ setting ]) 

    return (
        <>
            <View style={styles.container}>
                <View style={styles.wrapperStickyHeader}>
                    <HeaderMerchantRefundAmount
                        textHeader="再利用設定"
                        textHeaderTitle="対象決済金額から再利用分の割り当て及び払戻請求金額を設定します。"
                        textInfoTitle={"対象決済金額 (売上)      " + setting?.history?.settingAmount}
                        textInfoDateRange={`${setting?.history?.formattedPaymentStartDate}. ~ ${setting?.history?.formattedPaymentEndDate}.`}
                    />
                    <fieldset disabled={!(setting?.ableToChangeSetting)} style={{ padding: 0, border: 0 }}>

                        <View style={{ paddingBottom: 20 }}>
                            <Text style={[styles.inputLabel]}>{'再利用設定'}</Text>
                            <Grid>
                                <Col size={7} style={[styles.leftSearch, { minWidth: 800 }]}>
                                    <Row>
                                        <View style={styles.wrapperInput}>
                                            <Switch
                                                disabled={!(setting?.ableToChangeSetting)}
                                                onColor={BG_ACTIVE_SIDEBAR}
                                                checkedIcon={false}
                                                uncheckedIcon={false}
                                                onChange={(checked) => setSetting({ ...setting, transferToLevica: checked })}
                                                checked={setting?.transferToLevica ?? false}></Switch>
                                            <Text style={{ fontSize: 17 }}>{setting?.transferToLevica ? 'ON' : 'OFF'}</Text>
                                        </View>
                                    </Row>
                                </Col>
                            </Grid>
                        </View>

                        <Grid>
                            <Col style={{ width: "50%" }}>
                                <View style={{ paddingBottom: 20 }}>
                                    <Text style={[styles.inputLabel]}>{'払戻請求設定金額'} <span style={{ fontSize: "0.75em", marginLeft: "10px" }}>※対象決済金額に対する優先払い戻しの金額（円）</span></Text>
                                    <Grid>
                                        <Col size={12} style={[styles.leftSearch, { minWidth: 800 }]}>
                                            <Row>
                                                <View style={[styles.wrapperInput, { width: "auto", paddingRight: 0 }]}>
                                                    <Grid>
                                                        <Col>
                                                            <JNumber
                                                                width={400}
                                                                control={control}
                                                                name="refundAmount"
                                                                borderError={'#5C5C5C'}
                                                                heightInput={48}
                                                                fontSize={42}
                                                                textAlignInput="right"
                                                            />
                                                        </Col>
                                                    </Grid>
                                                </View>
                                                <View style={styles.wrapperInput}>
                                                    <LinearGradient colors={["#54dbb2", "#04c48b"]} style={{ borderRadius: 5, width: 146 }}>
                                                        <JButton
                                                            fontSize={18}
                                                            width={146}
                                                            btnDisabledColor={BTN_BG_COLOR_DISABLED}
                                                            textColorDisabled={BTN_TXT_COLOR_DISABLED}
                                                            text="確定"
                                                            textColor="#fff"
                                                            heightIcon={21}
                                                            widthIcon={21}
                                                            disabled={!getFieldState("refundAmount", formState).isDirty}
                                                            onPressAction={() => { setShouldShowAmountConfirmModal(true)}}
                                                        />
                                                    </LinearGradient>
                                                </View>
                                            </Row>
                                        </Col>
                                    </Grid>
                                </View>
                            </Col>
                        </Grid>
                    </fieldset>

                    <Grid>
                        <Col style={{ width: "50%" }}>
                            <View style={{ paddingBottom: 20 }}>
                                <Text style={[styles.inputLabel]}>{'送信先（再利用先）アドレス'} </Text>
                                <Grid>
                                    <Col size={12} style={[styles.leftSearch, { minWidth: 800 }]}>
                                        <Row>
                                            <View style={[styles.wrapperInput, { width: "auto", paddingRight: 0 }]} >
                                                <Grid>
                                                    <Col>
                                                        <JInput
                                                            width={550}
                                                            subfix="iconEdit.svg"
                                                            widthSubfix={18.35}
                                                            heightSubfix={18.35}
                                                            topSubfix={11}
                                                            control={control}
                                                            name="leviasChainAddress"
                                                            borderError={'#5C5C5C'}
                                                            heightInput={48}
                                                            fontSize={42}
                                                        />
                                                    </Col>
                                                </Grid>
                                            </View>
                                            <View style={styles.wrapperInput}>
                                                <LinearGradient colors={["#54dbb2", "#04c48b"]} style={{ borderRadius: 5, width: 146 }}>
                                                    <JButton
                                                        fontSize={18}
                                                        width={146}
                                                        btnDisabledColor={BTN_BG_COLOR_DISABLED}
                                                        textColorDisabled={BTN_TXT_COLOR_DISABLED}
                                                        text="確定"
                                                        textColor="#fff"
                                                        heightIcon={21}
                                                        widthIcon={21}
                                                        disabled={!getFieldState("leviasChainAddress", formState).isDirty}
                                                        onPressAction={() => { setShouldShowAddressConfirmModal(true) }}
                                                    />
                                                </LinearGradient>
                                            </View>
                                        </Row>
                                    </Col>
                                </Grid>
                            </View>
                        </Col>
                    </Grid>
                </View>
            </View>

            <Modal
                key="amountConfirmModal"
                backdropTransitionOutTiming={1}
                animationOutTiming={1}
                animationInTiming={1}
                isVisible={shouldShowAmountConfirmModal}
                style={styles.modal}
                customBackdrop={<ModalBackDrop onPress={() => { setShouldShowAmountConfirmModal(false) }} />}>
                <TouchableOpacity>
                    <View style={[styles.warper, {width: 707, height: 400}]}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.text}>{"上限償還金額の変更"}</Text>
                        </View>
                        <View style={[styles.modalBody]}>
                            <Text style={[styles.text, { paddingBottom: 55 }]}>{"上限償還金額を以下で変更しますか？ "}</Text>
                            <Text style={[styles.text, { fontSize: 30, paddingBottom: 65 }]}>{`+${getValues("refundAmount")}円`}</Text>
                            <View style={styles.flexRow}>
                                <View style={[styles.wrapperInput, {paddingLeft: 0}]}>
                                    <LinearGradient colors={["#56575F", "#56575F"]} style={{ borderRadius: 5, width: 270, height: 55 }}>
                                        <JButton
                                            style={styles.h55}
                                            linkIcon="close-white.svg"
                                            text="キャンセル"
                                            textColor="#fff"
                                            heightIcon={21}
                                            widthIcon={21}
                                            onPressAction={() => {setShouldShowAmountConfirmModal(false) }} />
                                    </LinearGradient>
                                </View>
                                <View style={[styles.wrapperInput, {paddingRight: 0}]}>
                                    <LinearGradient colors={["#54dbb2", "#04c48b"]} style={{ borderRadius: 5, width: 270, height: 55 }}>
                                        <JButton
                                            style={styles.h55}
                                            linkIcon="save.svg"
                                            text="変更する"
                                            textColor="#fff"
                                            heightIcon={21}
                                            widthIcon={21}
                                            onPressAction={() => { saveAmount()  }} />
                                    </LinearGradient>
                                </View>
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
            </Modal>


            <Modal
                backdropTransitionOutTiming={1}
                animationOutTiming={1}
                animationInTiming={1}
                isVisible={shouldShowAmountSavedModal}
                style={styles.modal}
                customBackdrop={<ModalBackDrop onPress={() => setShouldShowAmountSavedModal(false)} />}>
                <TouchableOpacity onPress={() => setShouldShowAmountSavedModal(false)}>
                    <View style={[styles.rowWarpper,  {width: 707, height: 95}]}>
                    <Image style={styles.iconLogout} source={require(`@assets/icons/successGreen.svg`)} />
                    <Text style={[styles.text,{ fontSize: 19}]}>{`上限償還金額が変更されました`}</Text>
                    </View>
                </TouchableOpacity>
            </Modal>


            <Modal
                key="accountConfirmModal"
                backdropTransitionOutTiming={1}
                animationOutTiming={1}
                animationInTiming={1}
                isVisible={shouldShowAddressConfirmModal}
                style={styles.modal}
                customBackdrop={<ModalBackDrop onPress={() => { setShouldShowAddressConfirmModal(false) }} />}>
                <TouchableOpacity>
                    <View style={[styles.warper, {width: 707, height: 400}]}>
                        <View style={styles.modalHeader}>
                            <Text style={styles.text}>{"連携レヴィアスチェーンアドレス"}</Text>
                        </View>
                        <View style={[styles.modalBody]}>
                            <Text style={[styles.text, { paddingBottom: 55 }]}>{"連携レヴィアスチェーンアドレスを以下に変更しますか？"}</Text>
                            <Text style={[styles.text, { fontSize: 20, paddingBottom: 65 }]}>{getValues("leviasChainAddress")}</Text>
                            <View style={styles.flexRow}>
                                <View style={[styles.wrapperInput, {paddingLeft: 0}]}>
                                    <LinearGradient colors={["#56575F", "#56575F"]} style={{ borderRadius: 5, width: 270, height: 55 }}>
                                        <JButton
                                            style={styles.h55}
                                            linkIcon="close-white.svg"
                                            text="キャンセル"
                                            textColor="#fff"
                                            heightIcon={21}
                                            widthIcon={21}
                                            onPressAction={() => {setShouldShowAddressConfirmModal(false) }} />
                                    </LinearGradient>
                                </View>
                                <View style={[styles.wrapperInput, {paddingRight: 0}]}>
                                    <LinearGradient colors={["#54dbb2", "#04c48b"]} style={{ borderRadius: 5, width: 270, height: 55 }}>
                                        <JButton
                                            style={styles.h55}
                                            linkIcon="save.svg"
                                            text="変更する"
                                            textColor="#fff"
                                            heightIcon={21}
                                            widthIcon={21}
                                            onPressAction={() => { saveLeviasChainAddress()  }} />
                                    </LinearGradient>
                                </View>
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
            </Modal>


            <Modal
                backdropTransitionOutTiming={1}
                animationOutTiming={1}
                animationInTiming={1}
                isVisible={shouldShowAddressSavedModal}
                style={styles.modal}
                customBackdrop={<ModalBackDrop onPress={() => setShouldShowAddressSavedModal(false)} />}>
                <TouchableOpacity onPress={() => setShouldShowAddressSavedModal(false)}>
                    <View style={[styles.rowWarpper,  {width: 707, height: 95, paddingRight: 0}]}>
                        <Image style={styles.iconLogout} source={require(`@assets/icons/successGreen.svg`)}/>
                        <Text style={[styles.text,{ fontSize: 19}]}>{`連携レヴィアスチェーンアドレスが変更されました`}</Text>
                    </View>
                </TouchableOpacity>
            </Modal>
        </>
    );
};

export default MerchantRefundAmount;
