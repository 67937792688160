import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, Image, TextInput, View } from 'react-native';

import { COLOR_TEXT_DEFAULT, INPUT_BG, TEXT_COLOR_HEADER_TABLE } from '@assets/style/styleDefault';
import { JText as Text } from './JText';

const JInput = ({
  colorTextInput = COLOR_TEXT_DEFAULT,
  placeholder,
  width = '100%',
  subfix,
  widthSubfix,
  heightSubfix,
  rightSubfix = 20,
  prefix,
  widthPrefix,
  heightPrefix,
  topPrefix = 10,
  control,
  name,
  backgroundColor = INPUT_BG,
  multiline,
  numberOfMulti,
  heightMulti,
  borderError = '#FF0000',
  paddingLeft,
  paddingRight,
  border = '1px solid transparent',
  marginBottomErr,
  disable = true,
  borderRadius = 5,
  secureTextEntry = false,
  heightInput = 42,
  textAlignInput,
  cursor,
  onPressEnter = null,
}) => {
  const [isFocus, setFocus] = useState(false);
  const handleKeyPress = (event) => {
    if (event.nativeEvent.key === 'Enter') {
      if (onPressEnter) {
        onPressEnter();
      }
    }
  };
  return (
    <>
      {prefix ? (
        <View style={[styles.relative, { width: width }]}>
          {prefix && (
            <Image
              style={[styles.iconPrefix, { width: widthPrefix, height: heightPrefix, top: topPrefix }]}
              source={require(`../../assets/icons/${prefix}`)}
            />
          )}
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error, ...rest } }) => (
              <>
                {multiline ? (
                  <UselessTextInput
                    placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                    multiline
                    numberOfLines={numberOfMulti}
                    style={[
                      styles.inputMulti,
                      { backgroundColor: backgroundColor, color: disable ? '#A5A5A5' : colorTextInput },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      paddingLeft && { paddingLeft: paddingLeft },
                      { height: heightMulti },
                      { cursor: cursor },
                    ]}
                    textAlign
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value ?? ''}
                    placeholder={placeholder}
                    editable={disable}
                  />
                ) : onPressEnter ? (
                  <TextInput
                    placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                    secureTextEntry={secureTextEntry}
                    caretHidden={secureTextEntry}
                    selectTextOnFocus={!secureTextEntry}
                    style={[
                      styles.input2,
                      {
                        backgroundColor: backgroundColor,
                        color: !disable ? '#A5A5A5' : colorTextInput,
                        height: heightInput,
                        outline: isFocus && !error && disable ? '2px solid #00BE85' : 'none',
                      },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      paddingLeft && { paddingLeft: paddingLeft },
                      { cursor: cursor },
                      { fontSize: secureTextEntry ? (value ? '30px' : '14px') : '14px' },
                    ]}
                    onChangeText={onChange}
                    placeholder={placeholder}
                    editable={disable}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    onKeyPress={handleKeyPress}
                  />
                ) : (
                  <TextInput
                    placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                    secureTextEntry={secureTextEntry}
                    caretHidden={secureTextEntry}
                    selectTextOnFocus={!secureTextEntry}
                    style={[
                      styles.input2,
                      {
                        backgroundColor: backgroundColor,
                        color: !disable ? '#A5A5A5' : colorTextInput,
                        height: heightInput,
                        outline: isFocus && !error && disable ? '2px solid #00BE85' : 'none',
                      },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      paddingLeft && { paddingLeft: paddingLeft },
                      { cursor: cursor },
                      { fontSize: secureTextEntry ? (value ? '30px' : '14px') : '14px' },
                    ]}
                    onChangeText={onChange}
                    value={value ?? ''}
                    placeholder={placeholder}
                    editable={disable}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    onKeyPress={handleKeyPress}
                  />
                )}
                <Text style={styles.error}>{error?.message}</Text>
              </>
            )}
            name={name}
          />
          {subfix && (
            <Image
              style={[styles.iconSubfix, { width: widthSubfix, height: heightSubfix, right: rightSubfix }]}
              source={require(`../../assets/icons/${subfix}`)}
            />
          )}
        </View>
      ) : (
        <View style={[styles.relative, { width: width }]}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error, ...rest } }) => (
              <>
                <TextInput
                  placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                  secureTextEntry={secureTextEntry}
                  style={[
                    styles.input,
                    {
                      backgroundColor: backgroundColor,
                      color: !disable ? '#A5A5A5' : colorTextInput,
                      height: heightInput,
                      outline: isFocus && !error && disable ? '2px solid #00BE85' : 'none',
                    },
                    multiline && { height: heightMulti, paddingTop: '10px' },
                    error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                    { paddingLeft: 20 },
                    marginBottomErr && {
                      marginBottom: marginBottomErr,
                    },
                    { borderRadius: borderRadius },
                    { textAlign: textAlignInput },
                    paddingRight && { paddingRight: paddingRight },
                    { cursor: cursor },
                  ]}
                  onChangeText={onChange}
                  value={value ?? ''}
                  placeholder={placeholder}
                  multiline={multiline}
                  editable={disable}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                />
                <Text style={styles.error}>{error?.message}</Text>
              </>
            )}
            name={name}
          />
          {subfix && (
            <Image
              style={[styles.iconSubfix, { width: widthSubfix, height: heightSubfix, right: rightSubfix }]}
              source={require(`../../assets/icons/${subfix}`)}
            />
          )}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  relative: {
    position: 'relative',
  },
  input: {
    fontSize: 14,
    fontFamily: 'NotoSansJP',
    borderRadius: 4,
    paddingLeft: 50,
    paddingRight: '3rem',
    outlineStyle: 'none',
    marginHorizontal: 2,
  },
  input2: {
    fontSize: 14,
    fontFamily: 'NotoSansJP',
    borderRadius: 4,
    paddingLeft: 50,
    paddingRight: '3rem',
    outlineStyle: 'none',
    marginHorizontal: 2,
  },
  inputMulti: {
    fontSize: 14,
    fontFamily: 'NotoSansJP',
    paddingLeft: 50,
    borderRadius: 4,
    paddingRight: '3rem',
    outlineStyle: 'none',
    marginHorizontal: 2,
  },
  iconSubfix: {
    position: 'absolute',
    top: 13,
  },
  iconPrefix: {
    position: 'absolute',
    left: 15,
  },
  error: {
    color: 'red',
    position: 'absolute',
    bottom: -25,
    left: 0,
  },
});

export default JInput;
